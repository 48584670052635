import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import SortableItem from "./SortableItem";

type SortableListProps = {
  items: any;
  render: (props: any) => React.ReactNode;
  onMove: (oldPosition: any, newPosition: any) => void;
};

function SortableList({ items, render, onMove }: SortableListProps) {
  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      return onMove(
        active.data.current.sortable.index,
        over.data.current.sortable.index
      );
    }
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      // eslint-disable-next-line react/jsx-no-bind
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item: any, index: any) => {
          return (
            <SortableItem
              key={`${items.value[index]?.id}_${items.value[index]?.random}`}
              index={index}
              id={item}
              name={item}
              render={render}
              fields={items}
            />
          );
        })}
      </SortableContext>
    </DndContext>
  );
}

export default SortableList;
