import { useCallback, useEffect, useState } from "react";

interface IStickyWrapper {
  children: React.ReactNode;
  isHeader?: boolean;
  isFilters?: boolean;
  isBeforeFilters?: boolean;
  isFooter?: boolean;
  inModal?: boolean;
  disabledOnMobile?: boolean;
  background?: string;
  from?: string;
  isRelative?: boolean;
}

function StickyWrapper({
  children,
  isHeader = false,
  isFilters = false,
  isBeforeFilters = false,
  isFooter = false,
  inModal = false,
  disabledOnMobile = false,
  background = "bg-slate-100",
  from = "from-slate-100",
  isRelative = false,
}: IStickyWrapper) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const overlayOpacity =
    (scrollPosition > 20 || inModal) && !disabledOnMobile
      ? "opacity-1"
      : "opacity-0";

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }, [setScrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const containerClasses = () => {
    if (isHeader) {
      return disabledOnMobile
        ? `relative md:sticky z-[60] ${inModal ? "top-0" : "top-0 md:top-4"}`
        : `sticky z-[60] ${inModal ? "top-0" : "top-[65px] md:top-4"}`;
    }
    if (isFilters) {
      return disabledOnMobile
        ? "relative md:sticky z-[50] top-0 md:top-[107px]"
        : "sticky z-[50] top-[107px]";
    }
    if (isFooter) {
      return disabledOnMobile
        ? "relative md:sticky z-[50] bottom-0"
        : "sticky z-[50] bottom-0";
    }

    return "sticky";
  };

  return (
    <div className={containerClasses()}>
      {isFooter ? (
        <>
          {!isRelative && (
            <div
              className={`${background} h-12 w-full absolute -bottom-8 z-0 pointer-events-none`}
            />
          )}
          <div
            className={`${background} h-8 w-full absolute -top-0 z-0 transition-all pointer-events-none`}
          />

          <div
            className={`bg-gradient-to-t ${from} h-4 w-full absolute -top-4 z-0 transition-all pointer-events-none`}
          />
        </>
      ) : (
        <>
          <div
            className={`${background} h-12 w-full absolute -top-4 z-0 pointer-events-none`}
          />
          <div
            className={`${background} h-8 w-full absolute -bottom-0 z-0 ${overlayOpacity} transition-all pointer-events-none`}
          />

          {!isBeforeFilters && (
            <div
              className={`bg-gradient-to-b ${from} h-12 w-full absolute -bottom-12 z-0 ${overlayOpacity} transition-all pointer-events-none`}
            />
          )}
        </>
      )}

      <div className="relative z-1">{children}</div>
    </div>
  );
}

export default StickyWrapper;
