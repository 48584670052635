import Icon, { IconNames } from "components/Icon/Icon";
import StickyWrapper from "../StickyWrapper/StickyWrapper";

interface IEditHeader {
  icon: IconNames;
  title: string;
  subTitle: string;
  status?: any;
  inModal?: boolean;
  errorMessage?: string;
  HeaderButtonsEdit?: React.ReactElement;
}

function EditHeader({
  icon,
  title,
  subTitle,
  status,
  inModal = false,
  errorMessage,
  HeaderButtonsEdit,
}: IEditHeader) {
  return (
    <StickyWrapper isHeader disabledOnMobile inModal={inModal}>
      <div className="z-50 white-box mb-6">
        <div
          className={`relative flex flex-col md:flex-row lg:items-center justify-between ${
            errorMessage ? "pb-7" : ""
          }`}
        >
          <div className="flex lg:items-center gap-4 max-w-full">
            <div className="hidden lg:flex items-center justify-center border border-primary w-14 min-w-[3.5rem] h-14 rounded-lg">
              <Icon name={icon} className="text-primary w-7 h-7" />
            </div>
            <div className="flex flex-col justify-center items-start overflow-hidden">
              <h1 className="text-3xl font-bold text-primary leading-9">
                {title}
              </h1>
              <span className="text-xxs uppercase mt-2 overflow-hidden text-ellipsis max-w-full whitespace-nowrap">
                {subTitle}
              </span>
            </div>
          </div>
          {status && (
            <div className="hidden lg:flex gap-4 my-3 lg:my-0">{status}</div>
          )}

          {/* BUTTONS */}
          {HeaderButtonsEdit}

          {errorMessage && (
            <div className="absolute -bottom-3 left-0 text-red-500">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </StickyWrapper>
  );
}

export default EditHeader;
