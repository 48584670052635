import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import useOrganization from "common/hooks/useOrganization";
import { Course } from "types/Course";

const processOptions = (options: Course[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function CourseSelect(props: any) {
  const { organization } = useOrganization();

  return (
    <RemoteSelect
      url="courses/private/data"
      processOptions={processOptions}
      filters={[{ name: "organization][id", value: organization?.id }]}
      sort="name:ASC"
      {...props}
    />
  );
}

export default CourseSelect;
