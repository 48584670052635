import { JText } from "../Text/Text";

type Props = {
  value: string | boolean | number;
  labelKey?: string;
  label?: string;
  selectedValue?: string | string[] | boolean | number | undefined;
  onChange?: (value: string | string[] | boolean | number | undefined) => void;
};

export function SelectableOptionButton({
  value,
  labelKey,
  label,
  selectedValue,
  onChange = () => {},
}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onOptionPressed = () => {
    if (
      typeof selectedValue === "string" ||
      typeof selectedValue === "boolean" ||
      typeof selectedValue === "number" ||
      selectedValue === null
    ) {
      onChange(value);
    } else if (Array.isArray(selectedValue)) {
      if (selectedValue?.length === 0) {
        onChange([value as string]);
      } else if (selectedValue?.includes(value as string)) {
        onChange(selectedValue?.filter((item) => item !== value));
      } else {
        const newTab: string[] = [...selectedValue, value as string];
        onChange(newTab);
      }
    } else {
      onChange(value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isSelected = () => {
    if (
      typeof selectedValue === "string" ||
      typeof selectedValue === "boolean" ||
      typeof selectedValue === "number"
    ) {
      return selectedValue === value;
    }

    return selectedValue?.includes(value as string);
  };

  return (
    <div>
      <JText
        labelKey={labelKey}
        label={
          label ||
          (typeof value === "number" ? (value as unknown as string) : value)
        }
        colorName="blueHigh"
        isBold
      />
    </div>
  );
}

export default SelectableOptionButton;
