import { useEffect, useCallback } from "react";
import fetchJSON from "common/utils/fetchJSON";
import CancellablePromise from "common/utils/CancellablePromise";
import useAsync, { UseAsyncOptions } from "./useAsync";

interface PromisesMap {
  [key: string]: CancellablePromise<unknown>;
}

interface SubscribersMap {
  [key: string]: number;
}

// Tableau pour enregistrer les promises de chaque url
const fetchPromises: PromisesMap = {};
// Tableau pour savoir le nombre de subscribers a une url
const nbSubscribers: SubscribersMap = {};

export type UseFetchOptions = {
  sharePromise?: boolean;
} & UseAsyncOptions;

export type UseFetchType<T> = {
  data: T | null;
  isFetching: boolean;
  error: string | null;
  fetchData: (...args: any[]) => CancellablePromise<unknown>;
  isPreviousData: boolean;
  isDataValid: boolean;
  setData: (value: T | null | ((value: T | null) => T | null)) => void;
};

const useFetch = <T,>(
  url: string,
  options: UseFetchOptions = {}
): UseFetchType<T> => {
  const opts = {
    storeData: true,
    sharePromise: false,
    cachePrefix: options.cachePrefix ?? url.replace(/[/?&]/g, "_"),
    ...options,
  };

  const fetchFn = useCallback(
    (fetchOptions = {}) => {
      const controller = new AbortController();
      const { signal } = controller;

      let promise: CancellablePromise<unknown>;

      const fetchOpts = {
        url,
        method: "GET",
        signal,
        ...fetchOptions,
      };

      if (fetchOpts.method === "GET") {
        if (!opts.sharePromise || !fetchPromises[url]) {
          fetchPromises[url] = fetchJSON(
            fetchOpts
          ) as CancellablePromise<unknown>;
          fetchPromises[url].cancel = () => {
            controller.abort();
          };
        }
        promise = fetchPromises[url];
      } else {
        promise = fetchJSON(fetchOpts) as CancellablePromise<unknown>;
        promise.cancel = () => {
          controller.abort();
        };
      }

      return promise;
    },
    [url, opts.sharePromise]
  );

  // Sur le "DidMount" on ajoute +1 au subscribers
  // Sur le "umount" on retire 1 au subscribers
  useEffect(() => {
    if (typeof nbSubscribers[url] === "undefined") {
      nbSubscribers[url] = 0;
      delete fetchPromises[url];
    }
    nbSubscribers[url] += 1;

    return () => {
      nbSubscribers[url] -= 1;
      // Si plus aucun composant mounté n'utilise le useShareFetch
      // on retire le resultat pour reforcer un refresh la prochaine fois
      if (nbSubscribers[url] === 0) {
        delete fetchPromises[url];
      }
    };
  }, [url]);

  const {
    execute: fetchData,
    isProcessing,
    isPreviousData,
    isDataValid,
    setData,
    data,
    error,
  } = useAsync<T>(fetchFn, opts);

  return {
    data,
    isFetching: isProcessing,
    error,
    fetchData,
    isDataValid,
    isPreviousData,
    setData,
  };
};

export default useFetch;
