import classNames from "classnames";
import Icon, { IconNames } from "components/Icon/Icon";
import { I18nKey } from "i18n";
import { useTranslation } from "react-i18next";

export interface IRadioBlockItem {
  id: number;
  icon?: IconNames;
  title?: string;
  titleKey?: I18nKey;
  subTitle?: string;
  subTitleKey?: I18nKey;
  containerSelStyle?: string;
  iconStyle?: string;
  iconContainerStyle?: string;
  iconSelStyle?: string;
  iconContainerSelStyle?: string;
  selected?: boolean;
  onClick?: () => void;
}

function RadioBlockItem({
  id,
  icon,
  title,
  titleKey,
  subTitle,
  subTitleKey,
  containerSelStyle,
  iconStyle,
  iconContainerStyle,
  iconSelStyle,
  iconContainerSelStyle,
  selected,
  onClick,
}: IRadioBlockItem) {
  const defaultContainerStyle =
    "bg-white text-slate-600 hover:border-secondary";

  const { t } = useTranslation();
  return (
    <div
      key={id}
      className={`border rounded-lg ${
        selected ? containerSelStyle : defaultContainerStyle
      } p-3 mt-2 cursor-pointer flex-1`}
      onClick={onClick}
    >
      <div className="flex gap-4 items-center">
        {icon && (
          <div
            className={`flex items-center justify-center ${
              selected ? iconContainerSelStyle : iconContainerStyle
            } w-12 h-12 rounded-lg shadow-sm`}
          >
            <Icon
              name={icon}
              className={`w-6 h-6 ${selected ? iconSelStyle : iconStyle}`}
            />
          </div>
        )}
        <div className="flex flex-col justify-center">
          <h1
            className={classNames(
              "text-xl font-bold",
              selected && "text-white",
              !selected && "text-primary"
            )}
          >
            {title || (titleKey ? t(titleKey) : "")}
          </h1>
          <span
            className={classNames(
              "text-xxs uppercase",
              selected && "text-white/80",
              !selected && "text-secondary"
            )}
          >
            {subTitle || (subTitleKey ? t(subTitleKey) : "")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default RadioBlockItem;
