import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Theme } from "types/Theme";

const processOptions = (options: Theme[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function ThemeSelect(props: any) {
  return (
    <RemoteSelect url="themes" processOptions={processOptions} {...props} />
  );
}

export default ThemeSelect;
