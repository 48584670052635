import {
  useState,
  /* useEffect, */
  // useRef,
  useCallback,
} from "react";
// import useEventListener from '@use-it/event-listener';

/*
const globalState = {};

const createGlobalState = (key, thisCallback, initialValue) => {
  if (!globalState[key]) {
    globalState[key] = { callbacks: [], value: initialValue };
  }
  globalState[key].callbacks.push(thisCallback);
  return {
    deregister() {
      const arr = globalState[key].callbacks;
      const index = arr.indexOf(thisCallback);

      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    emit(value) {
      if (globalState[key].value !== value) {
        globalState[key].value = value;
        globalState[key].callbacks.forEach((callback) => {
          if (thisCallback !== callback) {
            callback(value);
          }
        });
      }
    },
  };
}; */

const getProvider = () => {
  if (typeof global !== "undefined" && global.localStorage) {
    return global.localStorage;
  }
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== "undefined" && globalThis.localStorage) {
    // eslint-disable-next-line no-undef
    return globalThis.localStorage;
  }
  if (typeof window !== "undefined" && window.localStorage) {
    return window.localStorage;
  }
  if (typeof localStorage !== "undefined") {
    return localStorage;
  }
  return null;
};

const createStorage = (provider: any) => ({
  get(key: string, defaultValue: any) {
    const json = provider.getItem(key);
    // eslint-disable-next-line no-nested-ternary

    if (json === null || json === "null" || typeof json === "undefined") {
      return typeof defaultValue === "function" ? defaultValue() : defaultValue;
    }

    const parsed = JSON.parse(json);

    if (parsed.date === null || parsed.date >= Date.now()) {
      return parsed.value;
    }
    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  },
  set(key: string, value: any, cacheDuration?: number) {
    try {
      provider.setItem(
        key,
        JSON.stringify({
          date: cacheDuration ? Date.now() + cacheDuration * 1000 : null,
          value,
        })
      );
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }
  },
});

const storage = createStorage(getProvider());

/*
const createPersistedState = (key, provider = getProvider()) => {
  if (provider) {
    const storage = createStorage(provider);

    return (initialState) => usePersistedState(initialState, key, storage);
  }
  return useState;
}; */

const usePersistedState = (
  initialState: any,
  key: string,
  cacheDuration?: number
) => {
  // const globalState = useRef(null);
  const [state, setState] = useState(() => storage.get(key, initialState));

  // subscribe to `storage` change events
  /* useEventListener('storage', ({ key: k, newValue }) => {
    if (k === key) {
      const newState = JSON.parse(newValue);
      if (state !== newState) {
        setState(newState);
      }
    }
  }); */

  // only called on mount
  /* useEffect(() => {
    // register a listener that calls `setState` when another instance emits
    globalState.current = createGlobalState(key, setState, initialState);

    return () => {
      globalState.current.deregister();
    };
  }, [initialState, key]); */

  const persistentSetState = useCallback(
    (newState: any) => {
      setState((oldState: any) => {
        const newStateValue =
          typeof newState === "function" ? newState(oldState) : newState;

        // persist to localStorage
        storage.set(key, newStateValue, cacheDuration);

        // inform all of the other instances in this tab
        // globalState.current.emit(newState);

        return newStateValue;
      });
    },
    [key, cacheDuration]
  );

  return [state, persistentSetState];
};

export default usePersistedState;
