import ConfirmWrap from "common/components/ConfirmWrap/ConfirmWrap";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

type Props = {
  onClick?: () => void;
  label: string;
  confirm?: boolean;
  confirmMessage?: string;
};

function FormAddButton({
  onClick,
  label,
  confirm = false,
  confirmMessage = "",
}: Props) {
  const btn = (
    <div
      className="flex cursor-pointer gap-1 mt-2 min-h-[42px] text-sm text-white items-center justify-center w-full rounded-lg bg-primary  hover:bg-green-500 py-2 px-3 uppercase"
      onClick={onClick}
    >
      <PlusCircleIcon className="w-4 h-4" />
      {label}
    </div>
  );

  if (confirm) {
    return <ConfirmWrap message={confirmMessage}>{btn}</ConfirmWrap>;
  }
  return btn;
}

export default FormAddButton;
