import { useRef, useEffect, useState } from "react";

export type Props = {
  value?: string;
  onChange?: (val: any) => void;
  rows?: number;
  className?: string;
};

function TextArea({ rows = 5, value = "", className, ...props }: Props) {
  const textAreaRef = useRef({} as any);
  const [newRows, setNewRows] = useState(rows);

  // if (textAreaRef.current && textAreaRef.current.scrollHeight !== height) {
  //   setHeight(textAreaRef.current.scrollHeight);
  // }

  useEffect(() => {
    const textHeight = value.split("\n").length;
    if (textHeight > rows) {
      setNewRows(value.split("\n").length + 1);
    }
  }, [value, rows]);

  return (
    <textarea
      ref={textAreaRef}
      className={`input ${className} p-4 h-full text-sm rounded-lg overflow-y-auto`}
      value={value}
      rows={newRows}
      {...props}
    />
  );
}

export default TextArea;
