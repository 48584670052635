import { uniqueId } from "lodash";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";

import "./TooltipWrapper.css";

interface ITooltip {
  content: React.ReactElement | null;
  children: React.ReactElement | string | null;
  className?: string;
}

function TooltipWrapper({ children, content, className = "" }: ITooltip) {
  const id = uniqueId("tooltip");
  if (!content) {
    return <div>{children}</div>;
  }
  return (
    <>
      <a
        data-tooltip-id={id}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(content)}
        className={`leading-none ${className}`}
      >
        {children}
      </a>
      <Tooltip id={id} className="custom-tooltip" />
    </>
  );
}

export default TooltipWrapper;
