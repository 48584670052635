function SupportIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 588 588" {...props}>
      <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-324 -332)">
          <path d="M870 668c0 23.164-18.836 42-42 42h-21V584h21c23.164 0 42 18.836 42 42v42zM681 878c-11.577 0-21-9.423-21-21s9.423-21 21-21 21 9.423 21 21-9.423 21-21 21zM429 710h-21c-23.164 0-42-18.836-42-42v-42c0-23.164 18.836-42 42-42h21v126zm420-165.018V542c0-115.797-94.203-210-210-210h-42c-115.797 0-210 94.203-210 210v2.982c-36.126 9.393-63 41.994-63 81.018v42c0 46.317 37.683 84 84 84h42c11.597 0 21-9.403 21-21V563c0-11.597-9.403-21-21-21h-21c0-92.634 75.366-168 168-168h42c92.634 0 168 75.366 168 168h-21c-11.597 0-21 9.403-21 21v168c0 11.597 9.403 21 21 21h19.967c-6.237 65.688-41.717 80.02-66.114 83.103C730.914 811.17 708.011 794 681 794c-34.74 0-63 28.26-63 63 0 34.74 28.26 63 63 63 27.69 0 51-18.072 59.427-42.951 65.149-6.619 102.906-51.225 107.96-127.842C884.83 740.035 912 707.248 912 668v-42c0-39.024-26.874-71.625-63-81.018z" />
        </g>
      </g>
    </svg>
  );
}

export default SupportIcon;
