import DP from "react-datepicker";
import fr from "date-fns/locale/fr";
import { format } from "date-fns";

import Icon from "components/Icon/Icon";

import "./DatePicker.css";

const defaultButttonStyle =
  "inline-flex p-1 text-sm font-medium text-slate-700 bg-white border border-slate-300 rounded hover:bg-secondary hover:text-white hover:border-secondary focus:outline-none focus:ring-offset-0";

type Props = {
  onChange?: (value: any) => void;
  value?: any;
  placeholder?: string;
  showTimeSelect?: boolean;
};

function DatePicker({
  placeholder = "",
  value = null,
  onChange = () => {},
  showTimeSelect = true,
}: Props) {
  const formattedValue = !value ? null : new Date(value);
  const handleChange = (newDate: Date) => onChange(newDate);

  return (
    <DP
      // dateFormat="dd/MM/yyyy HH:mm"
      dateFormat="dd LLLL à HH:mm"
      locale={fr}
      selected={formattedValue}
      onChange={handleChange}
      placeholderText={placeholder}
      isClearable
      showTimeSelect={showTimeSelect}
      portalId="date-portal"
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex items-center justify-between px-2 py-2">
          <span className="font-bold capitalize text-lg text-slate-700">
            {format(date, "MMMM yyyy", { locale: fr })}
          </span>

          <div className="space-x-2">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={`
                        ${
                          prevMonthButtonDisabled &&
                          "cursor-not-allowed opacity-50"
                        }
                        ${defaultButttonStyle}
                    `}
            >
              <Icon name="ChevronLeftIcon" className="w-4 h-4" />
            </button>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={`
                        ${
                          nextMonthButtonDisabled &&
                          "cursor-not-allowed opacity-50"
                        }
                      ${defaultButttonStyle}`}
            >
              <Icon name="ChevronRightIcon" className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
    />
  );
}

export default DatePicker;
