import Icon, { IconNames } from "components/Icon/Icon";

const fileIcons: { [key: string]: IconNames } = {
  ".html": "BsFiletypeHtml",
  ".jpg": "BsFiletypeJpg",
  ".jpeg": "BsFiletypeJpg",
  ".png": "BsFiletypePng",
  ".xls": "BsFiletypeXls",
  ".xlsx": "BsFiletypeXlsx",
  ".doc": "BsFiletypeDoc",
  ".docx": "BsFiletypeDocx",
  ".pdf": "BsFiletypePdf",
};

interface IIFileIcon {
  ext?: string;
}

function FileIcon({ ext }: IIFileIcon) {
  if (!ext || !fileIcons[ext]) {
    return <Icon name="BsFileEarmark" className="w-8 h-8 shrink-0" />;
  }

  return <Icon name={fileIcons[ext]} className="w-8 h-8 shrink-0" />;
}

export default FileIcon;
