import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { UserType } from "types/UserType";

const processOptions = (options: UserType[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function UserTypeSelect(props: any) {
  return (
    <RemoteSelect url="user-types" processOptions={processOptions} {...props} />
  );
}

export default UserTypeSelect;
