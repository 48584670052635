import { EnvelopeIcon } from "@heroicons/react/24/outline";
import Button from "common/components/Button/Button";
import { Link } from "react-router-dom";
import { User } from "types";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import Status from "common/components/Status/Status";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";

function UserItem({ item, onDelete, onNavigate }: any) {
  const { user: currentUser } = useAuth();

  const canEditItem = currentUser?.isAdmin || currentUser?.isSuperadmin;

  const { name: fullname, email, role, avatar } = item as User;

  const editLink = `/users/edit/${item.id}`;

  const { t } = useTranslation();

  return (
    <div className="responsive-flex-lg px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem
            ? "pointer-events-none responsive-flex-lg flex-1"
            : "responsive-flex-lg flex-1"
        }
      >
        {role?.name !== "Authenticated" && (
          <div className="flex relative w-40 h-40 lg:w-16 lg:h-16 shrink-0">
            <div className="rounded-lg overflow-hidden w-full">
              <ImageComponent
                image={avatar ? ImageFromStrapiMedia(avatar)?.uri : undefined}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col flex-1 justify-between lg:pl-4 lg:pr-6">
          <div className="flex gap-3">
            <div className="flex-1 flex flex-col gap-2 py-3 lg:py-0">
              <h4 className="text-xl font-bold text-primary leading-4 mb-1">
                {fullname}
              </h4>

              {email && (
                <div className="flex items-center gap-1 text-sm">
                  <EnvelopeIcon className="w-3 h-3" />
                  <span className="font-bold">{email}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l pl-4 min-w-[22em] border-slate-150 gap-2">
        <div className={role ? "flex justify-end gap-2" : ""}>
          {role && <Status status={role?.name} />}
          <Status status={item.confirmed ? "confirmed" : "unconfirmed"} />
        </div>

        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />

              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserItem;
