import { useState, createContext, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

type AlertsContextType = {
  alerts: any[];
  setAlert: (message: any, type?: string) => void;
  clearAlerts: () => void;
  clearAlert: (id: number) => void;
};
const AlertsContext = createContext<AlertsContextType>({} as AlertsContextType);

export function AlertsProvider({ children }: any) {
  const [alerts, setAlerts] = useState<any[]>([]);

  const setAlert = useCallback(
    (message: any, type?: string) => {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        {
          id: uuid(),
          message,
          type,
        },
      ]);
    },
    [setAlerts]
  );

  const clearAlerts = useCallback(() => {
    setAlerts([]);
  }, []);

  const clearAlert = useCallback(
    (id: number) => {
      setAlerts(alerts.filter((a: any) => a.id !== id));
    },
    [alerts]
  );

  const value: AlertsContextType = useMemo(
    () => ({
      alerts,
      setAlert,
      clearAlerts,
      clearAlert,
    }),
    [alerts, setAlert, clearAlerts, clearAlert]
  );

  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  );
}

AlertsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AlertsContext;
