import {
  useParams,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import queryString from "query-string";

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch("");

  return {
    navigate,
    pathname: location.pathname,
    query: {
      ...queryString.parse(location.search),
      ...params,
    },
    match,
    location,
  };
};

export default useRouter;
