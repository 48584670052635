function NoiseIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="currentColor"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M125.585 71.203C56.34 71.203 0 127.542 0 196.788c0 35.443 17.5 60.679 36.028 87.387 17.283 24.915 35.151 50.679 41.387 88.085 4.962 29.764 15.962 50.198 32.698 60.727 8.255 5.189 17.698 7.811 27.896 7.811 6.17 0 12.613-.953 19.245-2.887 32.651-9.462 61.057-75.349 64.189-82.83 2.057-4.915-.264-10.576-5.189-12.641-4.915-2.057-10.576.255-12.642 5.189-10.443 24.962-33.736 66.509-51.745 71.736-8.462 2.443-20.811 3.972-31.462-2.736-11.868-7.462-19.915-23.453-23.934-47.538-6.943-41.679-26.934-70.5-44.566-95.924-17.481-25.208-32.585-46.981-32.585-76.377 0-58.594 47.67-106.264 106.264-106.264s106.264 47.67 106.264 106.264c0 5.34 4.321 9.66 9.66 9.66a9.655 9.655 0 009.66-9.66c.002-69.248-56.338-125.587-125.583-125.587z" />
      <path d="M168.703 264.793c5.37-15.672 11.788-42.781 4.891-70.344-5.755-23.028-21.245-38.707-39.462-39.943-9.085-.661-31.802 1.943-46.5 38.698-1.981 4.953.434 10.576 5.387 12.557 4.906 1.972 10.575-.425 12.557-5.387 6.934-17.339 17.208-27.094 27.255-26.594 9.689.661 18.34 10.613 22.028 25.349 8.641 34.575-8.764 70.283-8.943 70.641-.285.578-.502 1.17-.668 1.771-6.113 5.316-10.002 13.13-10.002 21.852 0 15.981 13 28.981 28.981 28.981 15.981 0 28.981-13 28.981-28.981 0-14.457-10.649-26.438-24.505-28.6zm-4.477 38.259c-5.33 0-9.66-4.33-9.66-9.66s4.33-9.66 9.66-9.66 9.66 4.33 9.66 9.66c.001 5.33-4.329 9.66-9.66 9.66zM502.34 255.099h-57.962c-2.934 0-5.708 1.156-7.547 3.448l-27.821 34.696-42.481-118.979c-1.415-3.953-5.34-6.455-9.368-6.436a9.662 9.662 0 00-8.99 6.924l-43.076 146.436-27.057-33.658c-1.84-2.292-4.613-3.451-7.547-3.451h-57.962c-5.34 0-9.66 4.321-9.66 9.66s4.321 9.66 9.66 9.66h53.321l35.736 44.505c1.858 2.312 4.642 3.535 7.547 3.535.623 0 1.245-.101 1.868-.224a9.684 9.684 0 007.396-6.776l39.925-135.745 38.321 107.267a9.652 9.652 0 007.5 6.281c3.425.556 6.943-.58 9.142-3.316l35.736-44.507h53.321c5.34 0 9.66-4.321 9.66-9.66s-4.323-9.66-9.662-9.66z" />
    </svg>
  );
}

export default NoiseIcon;
