interface IImageComponent {
  image?: string;
}
function ImageComponent({ image }: IImageComponent) {
  if (image) {
    return (
      <img
        src={image}
        className="object-cover object-center h-full w-full"
        alt=""
      />
    );
  }

  return (
    <img
      src="/images/fallback.jpg"
      className="object-cover object-center h-full w-full"
      alt=""
    />
  );
}

export default ImageComponent;
