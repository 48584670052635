import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import FormControl from "common/components/FormControl/FormControl";
import useAuth from "common/hooks/useAuth";
import Button from "common/components/Button/Button";
import { isMailCorrect } from "common/components/Password/Password";
import LoginHeader from "./LoginHeader";
import "./auth.css";

function ForgotPassword() {
  const { t } = useTranslation();

  const {
    forgotPassword,
    isFetching,
    emailErrorMessage,
    emailDone,
    setEmailDone,
  } = useAuth();

  const sendEmail = async ({ email }: any) => {
    try {
      await forgotPassword(email);
    } catch (error) {
      console.error(error);
    }
  };

  const checkEmail = (value: string) =>
    isMailCorrect(value) ? undefined : t("forms.badEmail");

  const content =
    emailDone && !emailErrorMessage ? (
      <div>
        <div className="text text-center text-slate-500 mb-4">
          {t("common.forgottenPasswordDone")}
        </div>

        <div>
          <Button
            type="primary"
            title={t("common.retryEmail")}
            className="w-full justify-center py-4"
            onClick={() => setEmailDone(false)}
          />
        </div>
      </div>
    ) : (
      <>
        <div className="text text-center text-slate-500 mb-4">
          {t("common.forgottenPasswordText")}
        </div>
        <Form
          onSubmit={sendEmail}
          render={({ handleSubmit }) => (
            <form
              className="flex flex-col gap-3"
              onSubmit={handleSubmit}
              noValidate
            >
              <FormControl
                name="email"
                title={t("common.email")}
                disabled={isFetching}
                validate={checkEmail}
              />
              {Boolean(emailErrorMessage) && (
                <div className="flex justify-center text-red-500">
                  {emailErrorMessage}
                </div>
              )}
              <div>
                <Button
                  type="primary"
                  title={t("common.sendEmail")}
                  className="w-full justify-center py-4"
                  loading={isFetching}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
        />
      </>
    );

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <LoginHeader />
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="login-box">
          <div className="text-xl text-center font-bold text-primary">
            {t("common.forgottenPassword")}
          </div>
          {content}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
