import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import Routes from "pages/Routes";
import Menu from "common/components/Menu/Menu";
import { useAuth } from "common/hooks/useAuth";
import routes from "routes";
import { LoadScript } from "@react-google-maps/api";
import Alerts from "common/components/Alerts/Alerts";

function AppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user: currentUser } = useAuth();

  return (
    <LoadScript
      googleMapsApiKey={
        // import.meta.env.VITE_GMAPS_API_KEY ??
        "AIzaSyBoabCFYUx-SQW3QtY0WmkRRuzsfhmQtCs"
      }
    >
      <div className="flex w-full font-display bg-slate-100">
        {currentUser && (
          <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40 md:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col sidebar">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                          <button
                            type="button"
                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </Transition.Child>
                      <div className="h-0 flex-1 overflow-y-auto pt-0 md:pt-5 pb-4">
                        <nav className="mt-0 md:mt-5 space-y-1 md:px-4">
                          <Menu
                            currentId="toto"
                            setSidebarOpen={setSidebarOpen}
                          />
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                  <div className="w-14 flex-shrink-0">
                    {/* Force sidebar to shrink to fit close icon */}
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:fixed md:inset-y-0 md:flex md:w-72 md:flex-col">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <nav className=" flex flex-1 flex-col py-4 space-y-1 px-4 min-h-0 overflow-hidden ">
                <Menu currentId="mainMenu" />
              </nav>
            </div>
          </>
        )}
        <div
          className={classNames(
            "flex flex-1 flex-col",
            currentUser && "md:ml-72 max-main-box"
          )}
        >
          <div className="sticky top-0 z-[1000] bg-gradient pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1  bg-slate-100">
            <Alerts />
            <Routes routes={routes} />
          </main>
        </div>
      </div>
    </LoadScript>
  );
}

export default AppLayout;
