import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import useOrganization from "common/hooks/useOrganization";
import { Point } from "types/Point";

const processOptions = (options: Point[]) =>
  options.map(({ id, name, point }) => ({
    value: { id, lat: (point as any)?.lat, lng: (point as any)?.lng },
    label: name,
  }));

function PointSelect(props: any) {
  const { organization } = useOrganization();

  return (
    <RemoteSelect
      url="points/private/data"
      processOptions={processOptions}
      filters={[{ name: "organization][id", value: organization?.id }]}
      sort="name:ASC"
      comparedField="id"
      {...props}
    />
  );
}

export default PointSelect;
