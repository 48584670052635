import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "../Select/Select";

function Geolocation({
  value = "",
  onChange = () => {},
  placeholder,
  ...props
}: any) {
  const { t } = useTranslation();
  const getOptions = useCallback((address: any, callback: any) => {
    if (address) {
      try {
        const request = {
          query: address,
          fields: ["formatted_address", "geometry"],
        };

        // eslint-disable-next-line no-undef
        const service = new google.maps.places.PlacesService(
          document.createElement("div")
        );

        service.textSearch(request, (results) => {
          if (!results || !results.length) {
            return;
          }

          const list = results.map(
            ({ formatted_address: val, geometry: { location } }: any) => ({
              label: val,
              value: val,
              location,
            })
          );

          callback(list);
        });
      } catch (e) {
        console.error(e);
        return [];
      }
      return null;
    }
    return [];
  }, []);

  /* On est obligé de passer par le script google car si on essaye de faire une requete fetch sur l'url problème de CORS
   * sur l'url que l'on veut utiliser
   */
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBoabCFYUx-SQW3QtY0WmkRRuzsfhmQtCs&libraries=places`; // &libraries=places
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const defaultOptions = value ? [{ label: value, value }] : [];

  return (
    <Select
      placeholder={placeholder || t("forms.geoTypeToSearch")}
      value={value}
      defaultOptions={defaultOptions}
      cacheOptions
      onChange={(val, row) => onChange(val, row)}
      loadOptions={getOptions}
      {...props}
    />
  );
}

export default Geolocation;
