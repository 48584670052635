// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from "react-player";

type PlayerProps = {
  url: string;
};

function Player({ url }: PlayerProps) {
  return <ReactPlayer url={url} style={{ height: "100%", width: "100%" }} />;
}

export default Player;
