import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useAuth from "common/hooks/useAuth";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import {
  isMailCorrect,
  isPasswordCorrect,
} from "common/components/Password/Password";
import useRouter from "common/hooks/use-router";
import EditHeader from "common/components/EditHeader/EditHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import RadioBlockItem from "common/components/RadioBlockItem/RadioBlockItem";
import rolesData from "conf/roleTypesData";
import useOrganization from "common/hooks/useOrganization";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";

function UserForm({ onSubmit, editedUser, myAccount }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();
  const { navigate } = useRouter();
  const [errorMessage, setErrorMessage] = useState("");
  const [changePassword, setChangePassword] = useState(false);

  const initialValues = useMemo(
    () =>
      editedUser?.id
        ? {
            ...editedUser,
            role: editedUser?.role?.id ?? 5,
            organization: currentUser?.isSuperadmin
              ? undefined
              : editedUser?.organization?.id ?? organization?.id,
            role_name: editedUser?.role?.name,
          }
        : {
            organization: organization?.id ?? currentUser?.organization?.id,
            username: undefined,
            password: undefined,
            role: 5,
            email: "",
            blocked: false,
            confirmed: true,
            maxCoursesCreation: 0,
            canPublish: "submit_yes_update_yes",
          },
    [
      currentUser?.isSuperadmin,
      currentUser?.organization?.id,
      editedUser,
      organization?.id,
    ]
  );

  const checkPassword = (value: string) =>
    isPasswordCorrect(value) ? undefined : t("common.passwordTooWeak");

  const checkEmail = (value: string) =>
    isMailCorrect(value) ? undefined : t("forms.badEmail");

  const checkMaxCoursesCreation = (value: any) =>
    value >= 0 ? undefined : t("forms.onlyNullOrPositiveValue");

  const validatePasswords = (values: any) => {
    if (values.password !== values.confirmPassword) {
      return { confirmPassword: t("common.resetPasswordError") };
    }
    return {};
  };

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  const displayRoleChoice =
    !myAccount &&
    (currentUser?.isAdmin || currentUser?.isSuperadmin) &&
    !initialValues.role_name?.includes([
      "SuperAdmin",
      "Public",
      "Authenticated",
    ]);

  const publicationChoices = [
    { value: "submit_yes_update_yes", label: t("forms.submitYesUpdateYes") },
    { value: "submit_no_update_yes", label: t("forms.submitNoUpdateYes") },
    { value: "submit_no_update_no", label: t("forms.submitNoUpdateNo") },
  ];

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      validate={validatePasswords}
      render={({ handleSubmit, values }) => (
        <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={
              myAccount
                ? `${t("titlePages.myAccount")}`
                : t(`common.${editedUser && editedUser.id ? "edit" : "add"}`)
            }
            subTitle={values.name}
            icon="UsersIcon"
            errorMessage={errorMessage}
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={myAccount ? null : () => navigate("/users")}
              />
            }
          />
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FormScrollError />

            <div className="responsive-flex gap-2 mt-2">
              <div className="md:w-2/3 flex flex-col gap-2">
                <div className="white-box">
                  {displayRoleChoice && (
                    <>
                      <SectionTitle
                        icon="CalendarDaysIcon"
                        title={t("forms.role")}
                      />
                      <FormControl
                        type="custom"
                        name="role"
                        inputContainerStyle="flex gap-3 mb-4"
                        containerStyle="w-full"
                      >
                        <RadioBlock
                          Component={RadioBlockItem}
                          items={rolesData}
                          horizontal
                        />
                      </FormControl>
                    </>
                  )}
                  <div className="responsive-flex gap-4">
                    {editedUser?.role?.name !== "Authenticated" && (
                      <div className="flex flex-col w-full md:w-[180px]">
                        <FormControl
                          title={t("forms.avatar")}
                          labelIcon="PhotoIcon"
                          name="avatar"
                          type="image"
                        />
                      </div>
                    )}
                    <div className="flex flex-col w-full gap-4">
                      {/* currentUser?.isAdmin && (
                        <FormControl
                          type="custom"
                          name="organization"
                          titleKey={t("forms.organization")}
                          containerStyle="mb-4"
                          multiple
                        >
                          <OrganizationSelect />
                        </FormControl>
                      ) */}

                      <FormControl
                        name="username"
                        titleKey={t("forms.name")}
                        required
                      />

                      {(currentUser?.isAdmin || currentUser?.isSuperadmin) && (
                        <FormControl type="custom" name="confirmed">
                          <ToggleSwitch
                            rightLabel={
                              values.confirmed
                                ? t("forms.confirmed")
                                : t("forms.unconfirmed")
                            }
                          />
                        </FormControl>
                      )}

                      {!!editedUser?.id && (
                        <ToggleSwitch
                          rightLabel={t("forms.newPassword")}
                          value={changePassword}
                          onChange={setChangePassword}
                        />
                      )}

                      {(changePassword || !editedUser?.id) && (
                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                          <FormControl
                            type="jpassword"
                            name="password"
                            titleKey={t("forms.password")}
                            helpText={t("common.passwordInstruction")}
                            required
                            validate={checkPassword}
                          />
                          <FormControl
                            name="confirmPassword"
                            title={t("forms.confirmPassword")}
                            type="password"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:w-1/3 gap-2">
                <div className="w-full">
                  <div className="white-box">
                    <div className="flex flex-col w-full gap-2">
                      <SectionTitle
                        title={t("forms.coordinates")}
                        icon="CalendarDaysIcon"
                      />

                      <FormControl
                        name="email"
                        titleKey={t("forms.email")}
                        required
                        validate={checkEmail}
                        // containerStyle="mb-2"
                      />
                    </div>
                  </div>
                </div>
                {values.role === 5 && !myAccount && (
                  <div className="w-full">
                    <div className="white-box">
                      <div className="flex flex-col w-full gap-2">
                        <SectionTitle
                          title={t("forms.settings")}
                          icon="CalendarDaysIcon"
                        />
                        <FormControl
                          name="maxCoursesCreation"
                          title={t("forms.maxCoursesCreation")}
                          type="number"
                          validate={checkMaxCoursesCreation}
                        />
                        <FormControl
                          type="select"
                          title={t("forms.publicationChoices")}
                          name="canPublish"
                          options={publicationChoices}
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={myAccount ? null : () => navigate("/users")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default UserForm;
