import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import useRouter from "common/hooks/use-router";

import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import { Point } from "types/Point";
import useOrganization from "common/hooks/useOrganization";
import { BsPinMap } from "react-icons/bs";
import { GoogleMap, Marker } from "@react-google-maps/api";
import mapStyle from "common/components/MapField/mapStyle";
import PointItem from "./PointItem";

const defaultCenter = {
  lat: 43.6043,
  lng: 1.4437,
};

const markerIcon = {
  url: `/images/map-marker.png`,
  scaledSize: { equals: () => false, width: 35, height: 45.5 },
};

const validPosition = (position: any) =>
  Boolean(position?.lat && position?.lng);

function Points() {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const { organization } = useOrganization();

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<Point>("points/private/data", {
    defaultFilters: {
      organization: organization?.id,
    },
    defaultSort: "name:ASC",
    cachePrefix: "Points",
  });
  const { removeItem } = useItem<Point>("points/private/data", "");

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        await removeItem(id);
        removeListItem(id);
        fetchItems();
      } catch (e: any) {
        setAlert(e?.message, "danger");
        return false;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const handleNavigate = (id: any) => {
    navigate(`/points/edit/${id}`);
  };

  const handleDuplicate = (id: any) => {
    navigate(`/points/duplicate/${id}`);
  };

  const handleMarkerClicked = (id: any) => {
    navigate(`/points/edit/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "w-1/2",
      },
    ],
    [t]
  );

  const mapItems = useMemo(
    () =>
      items?.map((item) => ({
        id: item.id,
        lat: (item.point as any).lat,
        lng: (item.point as any).lng,
      })),
    [items]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.points")}
        subTitle={t("titleLegends.points")}
        btnTitle={t("newItem.point")}
        btnSubtitle={t("newItem.pointLegend")}
        Icon={BsPinMap}
        addLink="/points/add"
        isBeforeFilters
      />

      <div className="flex flex-col lg:flex-row w-full gap-4 relative">
        <div className="flex flex-col lg:w-1/2">
          <div className="max-w-full rounded-lg h-[80vh] lg:h-[calc(100vh-8.5rem)] overflow-hidden mt-2 lg:sticky lg:top-[7.5rem]">
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={
                mapItems && mapItems?.length > 0 && validPosition(mapItems[0])
                  ? {
                      lat: mapItems[0].lat,
                      lng: mapItems[0].lng,
                    }
                  : defaultCenter
              }
              zoom={14}
              options={
                {
                  styles: mapStyle as unknown as google.maps.MapTypeStyle,
                  streetViewControl: false,
                  zoomControl: false,
                  mapTypeControl: false,
                } as unknown as google.maps.MapOptions
              }
            >
              {mapItems &&
                mapItems.length > 0 &&
                mapItems.map((item: any, index: number) => {
                  return (
                    validPosition(item) && (
                      <Marker
                        // eslint-disable-next-line react/no-array-index-key
                        key={`courseForm_readonly_${index}`}
                        position={{
                          lat: item.lat,
                          lng: item.lng,
                        }}
                        icon={markerIcon}
                        onClick={() => {
                          handleMarkerClicked(item.id);
                        }}
                      />
                    )
                  );
                })}
            </GoogleMap>
          </div>
        </div>
        <div className="lg:w-1/2">
          <FilteredList
            data={items ?? []}
            columns={columns} // used as filter items
            isFetching={isFetching}
            pageCount={pageCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            RenderItem={PointItem}
            onDelete={handleDelete}
            onNavigate={handleNavigate}
            onDuplicate={handleDuplicate}
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Points;
