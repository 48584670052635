import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import useOrganization from "common/hooks/useOrganization";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import MapField from "common/components/MapField/MapField";

function OrganizationForm({ onSubmit, organization }: any) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();
  const { organization: currentOrganization } = useOrganization();

  const initialValues = useMemo(
    () =>
      organization?.id
        ? {
            ...organization,
          }
        : {
            name: "",
            defaultMapPoint: {
              lat: 43.6043,
              lng: 1.4437,
            },
          },
    [organization]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        setPosition: (args: any, state: any, utils: any) => {
          const position = args[0];

          utils.changeValue(state, "defaultMapPoint.lat", () => position.lat);
          utils.changeValue(state, "defaultMapPoint.lng", () => position.lng);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setHelpPosition: ([_, geo], state, utils) => {
          utils.changeValue(
            state,
            "defaultMapPoint.lat",
            () => geo && geo.location?.lat()
          );
          utils.changeValue(
            state,
            "defaultMapPoint.lng",
            () => geo && geo.location?.lng()
          );
        },
      }}
      render={({ handleSubmit, values, form }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(
                currentOrganization?.id
                  ? "editItem.myOrganization"
                  : organization?.id
                  ? "editItem.organization"
                  : "newItem.organization"
              )}
              subTitle={values.name}
              icon="BuildingOfficeIcon"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={
                    currentOrganization?.id
                      ? null
                      : () => navigate("/organizations")
                  }
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex-lg gap-2 mt-2">
                <div className="lg:flex-1">
                  <div className="flex flex-col gap-2 w-full">
                    <div className="white-box flex flex-col gap-2">
                      <FormControl
                        name="name"
                        title={t("common.name")}
                        required
                      />
                      <MapField
                        position={{
                          lat: values.defaultMapPoint?.lat || 0,
                          lng: values.defaultMapPoint?.lng || 0,
                        }}
                        onChange={form.mutators.setPosition}
                        editable
                        mapPrefix="organizationForm"
                        form={form}
                        mapKey="forms.defaultMapPoint"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/organizations")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default OrganizationForm;
