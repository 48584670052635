import { FormSpy } from "react-final-form";

function FormScrollError() {
  return (
    <FormSpy
      subscription={{ submitFailed: true }}
      onChange={() => {
        const el = document.querySelector(".fieldError");

        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }}
    />
  );
}

export default FormScrollError;
