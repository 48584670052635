import Icon, { IconNames } from "components/Icon/Icon";

interface ISectionTitle {
  title: string;
  icon?: IconNames;
  className?: string;
}

function SectionTitle({ title, icon, className = "" }: ISectionTitle) {
  return (
    <div className={`section-title ${className}`}>
      {icon && <Icon name={icon} className="w-4 h-4" />}
      {title}
    </div>
  );
}

export default SectionTitle;
