import {
  BookmarkIcon,
  BuildingOfficeIcon,
  HomeIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import SupportIcon from "components/Icon/icons/SupportIcon";
import { IconNames } from "components/Icon/Icon";
import { GiPathDistance } from "react-icons/gi";
import { BsPinMap } from "react-icons/bs";
import { IoStatsChartOutline } from "react-icons/io5";

export type IMenuChild = {
  id: string;
  label: string;
  Icon: any;
  disableBoard?: boolean;
  add?: boolean;
  addRightType?: string;
  addRightKey?: string;
  addRightKeyJoker?: string[];
  useWordingPlural?: boolean;
};
export type IMenuGroup = {
  label: string;
  children: IMenuChild[];
  icon: IconNames;
  useWordingPlural?: boolean;
};

export type IMenuConf = Record<string, IMenuGroup>;

/** ******************************** Sections ******************************* */

const managementSection: IMenuConf = {
  // suivi
  suivi: {
    label: "monitoring",
    icon: "MdOutlineChecklist",
    children: [
      {
        id: "./",
        label: "board",
        Icon: HomeIcon,
        disableBoard: true,
      },
      {
        id: "courses",
        label: "courses",
        Icon: GiPathDistance,
        add: true,
        addRightType: undefined, // todo
        addRightKey: "canManageActivity",
        addRightKeyJoker: ["isAdmin", "isSuperadmin"],
      },
      {
        id: "points",
        label: "points",
        Icon: BsPinMap,
        add: true,
        addRightType: undefined, // todo
        addRightKey: "canManageActivity",
        addRightKeyJoker: ["isAdmin", "isSuperadmin"],
      },
      {
        id: "stats",
        label: "stats",
        Icon: IoStatsChartOutline,
        add: true,
        addRightType: undefined, // todo
        addRightKey: "canManageActivity",
        addRightKeyJoker: ["isAdmin", "isSuperadmin"],
      },
    ],
  },
};

const organizationsSection: IMenuChild[] = [
  {
    id: "organizations",
    label: "organizations",
    Icon: BuildingOfficeIcon,
  },
];

const dataSection: IMenuChild[] = [
  {
    id: "themes",
    label: "themes",
    Icon: BookmarkIcon,
    add: true,
    addRightType: undefined, // todo
    addRightKey: "canManageActivity",
    addRightKeyJoker: ["isSuperadmin"],
  },
  {
    id: "userTypes",
    label: "userTypes",
    Icon: UserGroupIcon,
    add: true,
    addRightType: undefined, // todo
    addRightKey: "canManageActivity",
    addRightKeyJoker: ["isSuperadmin"],
  },
];

const mySpaceSection: IMenuChild[] = [
  {
    id: "my-account",
    label: "myAccount",
    Icon: UserIcon,
  },
  {
    id: "support",
    label: "support",
    Icon: SupportIcon,
  },
];

// const usersSection = {
//   id: "users",
//   label: "users",
//   Icon: UsersIcon,
//   add: true,
//   addRightType: "role",
//   addRightKey: "isAdmin",
// };

// const myOrganizationSection = {
//   id: "my-organization",
//   label: "myOrganization",
//   Icon: BuildingOfficeIcon,
// };

const adminUsersSection: IMenuChild[] = [
  {
    id: "users",
    label: "publicAccounts",
    Icon: UsersIcon,
    add: false,
  },
];

/** **********************************  Menus by roles ************************** */

export const proMenu: IMenuConf = {
  ...managementSection,
  reglages: {
    label: "params",
    icon: "IoSettingsOutline",
    children: [...mySpaceSection],
  },
};

export const superadminRootMenu: IMenuConf = {
  // ...managementSection,
  reglages: {
    label: "params",
    icon: "IoSettingsOutline",
    children: [...organizationsSection, ...dataSection, ...mySpaceSection],
  },
};

export const superadminFilteredMenu: IMenuConf = {
  ...managementSection,
  reglages: {
    label: "params",
    icon: "IoSettingsOutline",
    children: [...dataSection, ...adminUsersSection, ...mySpaceSection],
  },
};
