import { CgSpinner } from "react-icons/cg";

import ConfirmWrap from "common/components/ConfirmWrap/ConfirmWrap";
import Icon, { IconNames } from "components/Icon/Icon";

const buttonStyle =
  "flex items-center text-sm uppercase py-2 rounded-lg gap-1 transition-all leading-3 cursor-pointer";

const getColors = (type?: string, disabled?: boolean) => {
  switch (type) {
    case "primary":
      return `bg-primary text-white ${!disabled && "hover:bg-primary-600"}`;
    case "secondary":
      return `bg-secondary text-white ${!disabled && "hover:bg-secondary-600"}`;
    case "ternary":
      return `bg-ternary text-white ${!disabled && "hover:bg-ternary-600"}`;
    case "neutral":
      return `bg-slate-500 text-white ${!disabled && "hover:bg-slate-400"}`;
    case "valid":
      return `bg-emerald-500  text-white ${
        !disabled && "hover:bg-emerald-400"
      }`;
    case "error":
      return `bg-red-500  text-white ${!disabled && "hover:bg-red-400"}`;
    case "neutral-line":
      return `border bg-white border-slate-200 text-slate-500  ${
        !disabled &&
        "hover:border-slate-500 hover:text-white hover:bg-slate-500"
      }`;
    case "valid-line":
      return `border bg-white text-emerald-500 border-emerald-300  ${
        !disabled &&
        "hover:bg-emerald-500 hover:text-white hover:border-emerald-500"
      }`;
    case "warning-line":
      return `border text-orange-400 border-orange-300  ${
        !disabled &&
        "hover:bg-orange-500 hover:text-white hover:border-orange-500"
      }`;
    case "slate-warning-line":
      return `border text-slate-400 border-slate-200  ${
        !disabled &&
        "hover:bg-orange-500 hover:text-white hover:border-orange-500"
      }`;
    case "error-line":
      return `border bg-white text-slate-500 border-slate-200  ${
        !disabled && "hover:bg-red-500 hover:text-white hover:border-red-500"
      }`;
    case "primary-line":
      return `border bg-white text-primary-500 border-primary-100  ${
        !disabled && "hover:bg-primary hover:text-white hover:border-primary"
      }`;
    default:
      return "";
  }
};

const getLoadingColor = (type?: string) => {
  switch (type) {
    case "primary":
    case "secondary":
    case "ternary":
    case "neutral":
    case "valid":
    case "error":
      return "text-white";
    case "neutral-line":
    case "valid-line":
    case "warning-line":
    case "slate-warning-line":
    case "error-line":
    case "primary-line":
      return "group-hover:text-white";
    default:
      return "";
  }
};

type Props = {
  onClick?: () => void;
  type?: string;
  icon?: IconNames;
  color?: string;
  confirm?: boolean;
  confirmMessage?: string;
  confirmMessageYes?: string;
  confirmMessageNo?: string;
  label?: string;
  transparent?: boolean;
  outlined?: boolean;
  loading?: boolean;
  className?: string;
  title?: string;
  compact?: boolean;
  small?: boolean;
  disabled?: boolean;
  thirdChoiceConfirmAction?: () => void;
  thirdChoiceConfirmLabel?: string;
};

function Button({
  onClick,
  icon,
  // color,
  confirm = false,
  // transparent = false,
  confirmMessage,
  confirmMessageYes,
  confirmMessageNo,
  title,
  // outlined = false,
  loading = false,
  compact,
  small,
  type,
  className,
  disabled = false,
  thirdChoiceConfirmAction,
  thirdChoiceConfirmLabel,
  ...rest
}: Props) {
  /* const classNames = ["button", additionnalClasses];

  if (transparent) classNames.push("is-transparent");
  if (outlined) classNames.push("is-outlined");
  if (color) classNames.push(`is-${color}`); */

  const colors = getColors(type, disabled);
  const loadingColors = getLoadingColor(type);
  const padding = !compact ? "py-2 px-4" : "p-2 h-8";
  const iconSize = small ? "w-3 h-3" : "w-4 h-4";

  const btn = (
    <button
      type="button"
      className={`${buttonStyle} ${colors} ${padding} ${className} ${
        disabled && "opacity-60"
      }`}
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      {loading && (
        <CgSpinner className={`w-4 h-4 animate-spin ${loadingColors}`} />
      )}
      {icon && !loading && <Icon name={icon} className={iconSize} />}
      {title || null}
    </button>
  );

  if (confirm || confirmMessage) {
    return (
      <ConfirmWrap
        message={confirmMessage}
        confirmMessageYes={confirmMessageYes}
        confirmMessageNo={confirmMessageNo}
        thirdChoiceConfirmAction={thirdChoiceConfirmAction}
        thirdChoiceConfirmLabel={thirdChoiceConfirmLabel}
      >
        {btn}
      </ConfirmWrap>
    );
  }
  return btn;
}

export default Button;
