import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import useOrganization from "common/hooks/useOrganization";
import { CourseHistory } from "types/CourseHistory";
import { IoStatsChartOutline } from "react-icons/io5";
import CourseSelect from "components/select/CourseSelect/CourseSelect";
import { GiPathDistance } from "react-icons/gi";
import UserTypeSelect from "components/select/UserTypeSelect/UserTypeSelect";
import CourseHistoryItem from "./CourseHistoryItem";
import StatsCustomHeader from "./StatsCustomHeader";
import StatsResult from "./StatsResult";

function Stats() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const { organization } = useOrganization();

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<CourseHistory>("course-histories/private/data", {
    defaultFilters: {
      organization: organization?.id,
    },
    defaultSort: "courseName:ASC",
    cachePrefix: "CourseHistories",
  });
  const { removeItem } = useItem<CourseHistory>(
    "course-histories/private/data",
    ""
  );

  const { items: stats, setFilters } = useList<any>(
    "course-histories/private/stats",
    {
      defaultFilters: {
        organization: organization?.id,
      },
      cachePrefix: "CourseHistoriesStats",
    }
  );

  useEffect(() => {
    setFilters(filters);
  }, [filters, setFilters]);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("forms.courseName"),
        filterName: "courseName$contains",
        filterLabel: t("filters.search"),
        columnClasses: "w-full",
      },
      {
        Header: t("forms.course"),
        filterName: "course][id",
        columnClasses: "w-full",
        filterComponent: (props: any) => (
          <CourseSelect
            isFilter
            allowReadOnlyOptions
            Icon={GiPathDistance}
            {...props}
          />
        ),
      },
      {
        Header: t("forms.userType"),
        filterName: "userType][id",
        columnClasses: "w-full",
        filterComponent: (props: any) => (
          <UserTypeSelect
            isFilter
            allowReadOnlyOptions
            Icon={GiPathDistance}
            {...props}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.stats")}
        subTitle={t("titleLegends.stats")}
        Icon={IoStatsChartOutline}
        isBeforeFilters
      />

      <StatsCustomHeader
        columns={columns}
        filters={filters}
        setFilter={setFilter}
      />

      <div className="flex flex-col lg:flex-row w-full gap-4 relative">
        {items?.length ? (
          <div className="flex flex-col lg:w-1/2">
            <div className="max-w-full rounded-lg h-[80vh] lg:h-[calc(100vh-13rem)] overflow-hidden mt-2 lg:sticky lg:top-[7.5rem]">
              <StatsResult stats={stats} />
            </div>
          </div>
        ) : null}
        <div className={items?.length ? "lg:w-1/2" : "lg:w-full"}>
          <FilteredList
            data={items ?? []}
            columns={columns} // used as filter items
            isFetching={isFetching}
            pageCount={pageCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            RenderItem={CourseHistoryItem}
            onDelete={handleDelete}
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
            noHeader
          />
        </div>
      </div>
    </div>
  );
}

export default Stats;
