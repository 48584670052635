function StatsSMS(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 589" {...props}>
      <g fill="currentColor" strokeWidth="1">
        <g transform="translate(-1206 -331)">
          <g transform="translate(1206 330.612)">
            <path d="M98.43 85.388h33.44L164 181.816l-49.65 33.099a20.995 20.995 0 00-8.73 22.508C135 356.388 234.54 455.4 353.29 483.814a20.999 20.999 0 0022.52-9.003l31.98-49.362L504 457.523v33.325c0 31.172-25.37 56.54-56.54 56.54-108.33 0-210.16-42.195-286.75-118.802S41.97 250.127 42 141.795c.01-31.1 25.32-56.407 56.43-56.407zm349.03 504c54.33 0 98.54-44.205 98.54-98.54v-48.46c0-9.044-5.78-17.062-14.36-19.923l-126-42c-9.09-3.025-19.05.482-24.27 8.5l-32.51 50.183c-92.89-27.655-170.23-104.641-198.59-197.706l50.38-33.581a21.004 21.004 0 008.27-24.118l-42-126A20.998 20.998 0 00147 43.388H98.43C44.16 43.388.01 87.531 0 141.784c-.04 119.551 46.49 231.954 131 316.498 84.53 84.543 196.91 131.106 316.46 131.106z" />
            <path d="M312 252.388h25v-76h-25v76zm31-101h-38c-10.48-.014-18.986 8.426-19 18.85v88.25c-.014 10.424 8.47 18.886 18.95 18.9H343c10.48.014 18.986-8.426 19-18.85v-88.25c.014-10.424-8.47-18.885-18.949-18.9h-.05z" />
            <path d="M406 252.388h25v-151h-25v151zm31.25-176h-37.5c-10.341-.014-18.736 8.4-18.75 18.794v163.363c-.014 10.392 8.359 18.83 18.7 18.843h37.55c10.342.014 18.736-8.4 18.75-18.793V95.232c.014-10.393-8.358-18.83-18.7-18.844h-.05z" />
            <path d="M499 252.388h25v-227h-25v227zm32-252h-38c-10.48-.014-18.986 8.42-19 18.836V258.502c-.014 10.416 8.47 18.872 18.95 18.886H531c10.48.014 18.986-8.42 19-18.836V19.275C550.014 8.858 541.53.403 531.051.388h-.05z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default StatsSMS;
