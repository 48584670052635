class CancellablePromise<T> extends Promise<T> {
  public cancelMethod: (() => void) | null = null;

  public cancel() {
    if (this.cancelMethod) {
      this.cancelMethod();
    }
  }
}

export default CancellablePromise;
