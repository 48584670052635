/* eslint-disable react/no-array-index-key */
import { SelectableOptionButton } from "../SelectableOptionButton/SelectableOptionButton";
import { JText } from "../Text/Text";

export type SelectableListProps = {
  values?: string[] | boolean[] | number[];
  labelKeys?: string[];
  selectedValue?: string | string[] | boolean | number | undefined;
  onChange?: (value: string | string[] | boolean | number | undefined) => void;
  label?: string[];
};

export function SelectableOptionsList(props: SelectableListProps) {
  return (
    <div>
      {Array.isArray(props.selectedValue) && (
        <JText labelKey="common.multipleSelection" />
      )}

      <div>
        {props.values?.map((item, index) => {
          return (
            <div key={index}>
              <SelectableOptionButton
                value={item}
                label={props.label ? props.label[index] : undefined}
                labelKey={props.labelKeys ? props.labelKeys[index] : undefined}
                selectedValue={props.selectedValue}
                onChange={props.onChange}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
