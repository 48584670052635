// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PointForm from "./PointForm";

function Point() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;
  const isDuplicated = router.pathname.includes("duplicate");

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "points/private/data",
    id
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/points");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataPoint: any) => {
      const newPoint = { ...dataPoint };

      if (isDuplicated) {
        delete newPoint.id;
      }

      try {
        if (newPoint.id) {
          await updateItem(newPoint.id, newPoint);
        } else {
          await saveItem(newPoint);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [isDuplicated, back, updateItem, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <PointForm onSubmit={onSubmit} point={item} />
  );
}

export default Point;
