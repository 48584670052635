/* eslint-disable @typescript-eslint/no-unused-vars */
import { I18nKey } from "i18n";
import { useTranslation } from "react-i18next";
import Types from "types/Types";

type Props = {
  label?: string | boolean;
  labelKey?: I18nKey;
  valuesKey?: object;
  colorName?: string; // ColorType;
  fontWeight?: Types["fontWeights"];
  textType?: Types["textType"];
  sizeName?: number; // FontSizeType;
  isUppercase?: boolean;
  isBold?: boolean;
  isItalic?: boolean;
  centered?: boolean;
  right?: boolean;
  isTitleText?: boolean;
  isButtonText?: boolean;
  style?: object; // StyleProp<div>;
};

export function JText({
  label,
  labelKey,
  valuesKey,
  colorName,
  fontWeight,
  textType = "p",
  sizeName,
  isUppercase = false,
  isBold = false,
  isItalic = false,
  centered = false,
  right = false,
  isTitleText = false,
  isButtonText = false,
  style,
}: Props) {
  const { t } = useTranslation();

  // const { theme } = useTheme();

  const labelResult = label || labelKey; // ? t(labelKey, valuesKey) : label;

  /* const font = () => {
    const defaultColor = theme.colors.dark;
    const defaultSize =
      textType === 'h1'
        ? theme.fontSizes.largest
        : textType === 'h2'
        ? theme.fontSizes.larger
        : textType === 'h3'
        ? theme.normalizeFont(18)
        : theme.fontSizes.large;

    if (isTitleText) {
      return {
        fontFamily: theme.fonts.fontTitle,
        fontSize: sizeName ? theme.fontSizes[sizeName] : defaultSize,
        fontWeight: fontWeight || '700',
        marginBottom: theme.sizings.large,
        color: colorName ? theme.colors[colorName] : theme.colors.blueHigh,
      };
    }

    if (isButtonText) {
      return {
        fontFamily: theme.fonts.fontTitle,
        fontSize: sizeName
          ? theme.fontSizes[sizeName]
          : theme.normalizeFont(18),
        fontWeight: fontWeight || '700',
        color: colorName ? theme.colors[colorName] : defaultColor,
      };
    }

    return {
      fontFamily: theme.fonts.fontBody,
      fontSize: sizeName ? theme.fontSizes[sizeName] : theme.fontSizes.large,
      color: colorName ? theme.colors[colorName] : defaultColor,
    };
  };

  const templateFont = font(); */

  return <span>{labelResult}</span>;
}

/*
 style={[
        isBold ? { fontWeight: '700' } : { fontWeight },
        isUppercase && { textTransform: 'uppercase' },
        templateFont,
        {
          textAlign: centered ? 'center' : right ? 'right' : 'left',
          fontStyle: isItalic ? 'italic' : 'normal',
        },
        style,
      ]}
      */

export default JText;
