import { useTranslation } from "react-i18next";
import Select from "common/components/Select/Select";

function RoleSelect(props: any) {
  const { t } = useTranslation();

  const optionsType = props.optionsType || "adminOptions";

  const superadminOptions = [
    // { value: "Authenticated", label: t("roles.Authenticated") },
    { value: "Pro", label: t("roles.Pro") },
    { value: "Administrateur", label: t("roles.Administrateur") },
    { value: "SuperAdmin", label: t("roles.SuperAdmin") },
  ];

  const adminOptions = [
    { value: "Pro", label: t("roles.Pro") },
    { value: "Administrateur", label: t("roles.Administrateur") },
  ];

  return (
    <Select
      options={
        optionsType === "adminOptions" ? adminOptions : superadminOptions
      }
      icon="StarIcon"
      {...props}
    />
  );
}

export default RoleSelect;
