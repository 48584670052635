import { Switch } from "@headlessui/react";
import classNames from "classnames";

const getColor = (enabled: boolean, isDoubleActive: boolean) => {
  if (isDoubleActive) {
    return "bg-secondary";
  }

  return enabled ? "bg-secondary" : "bg-gray-200";
};

function InactiveIcon() {
  return (
    <svg className="h-3 w-3 text-slate-400" fill="none" viewBox="0 0 12 12">
      <path
        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ActiveIcon() {
  return (
    <svg
      className="h-3 w-3 text-secondary"
      fill="currentColor"
      viewBox="0 0 12 12"
    >
      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
    </svg>
  );
}

interface IToggleSwitch {
  value?: boolean;
  isDoubleActive?: boolean;
  onChange?: any;
  leftLabel?: string;
  rightLabel?: string;
  isDisabled?: boolean;
  legende?: string;
}

const switchStyle =
  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none";
const optionStyle =
  "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity";
const containerStyle =
  "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out";
const labelStyle = "cursor-pointer hover:text-secondary";

function ToggleSwitch({
  isDoubleActive = false,
  onChange = null,
  value = false,
  leftLabel,
  rightLabel,
  isDisabled = false,
  legende,
}: IToggleSwitch) {
  const disabledStyle = classNames(
    value
      ? "opacity-0 ease-out duration-100"
      : "opacity-100 ease-in duration-200"
  );
  const enabledStyle = classNames(
    value
      ? "opacity-100 ease-in duration-200"
      : "opacity-0 ease-out duration-100",
    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
  );

  return (
    <Switch.Group>
      <div
        className={`flex items-center gap-2 ${
          isDisabled ? "opacity-60 pointer-events-none" : ""
        }`}
      >
        {leftLabel && (
          <Switch.Label className={labelStyle}>{leftLabel}</Switch.Label>
        )}
        <Switch
          checked={value}
          onChange={isDisabled ? undefined : () => onChange(!value)}
          className={classNames(getColor(value, isDoubleActive), switchStyle)}
        >
          <span
            className={classNames(
              value ? "translate-x-5" : "translate-x-0",
              containerStyle
            )}
          >
            <span
              className={`${disabledStyle} ${optionStyle}`}
              aria-hidden="true"
            >
              {isDoubleActive ? <ActiveIcon /> : <InactiveIcon />}
            </span>
            <span
              className={`${enabledStyle} ${optionStyle}`}
              aria-hidden="true"
            >
              <ActiveIcon />
            </span>
          </span>
        </Switch>
        {rightLabel && (
          <Switch.Label className={labelStyle}>
            <span> {rightLabel}</span>
            {legende && <p className="italic text-sm">{legende}</p>}
          </Switch.Label>
        )}
      </div>
    </Switch.Group>
  );
}

export default ToggleSwitch;
