import Icon from "components/Icon/Icon";
import { useTranslation } from "react-i18next";

function StatsResult({ stats }: any) {
  const { t } = useTranslation();

  const itemStyleInfo =
    "flex flex-col items-center justify-center px-3 py-3 bg-blue-100 rounded-lg border border-2 border-blue-200";

  const itemStyleSuccess =
    "flex flex-col items-center justify-center px-3 py-3 bg-emerald-100 rounded-lg border border-2 border-emerald-200";

  const itemStyleWarning =
    "flex flex-col items-center justify-center px-3 py-3 bg-orange-100 rounded-lg border border-2 border-orange-200";

  const keyStyle = "text-4xl font-bold text-slate-700 text-center";
  const keyStyleSmall = "text-2xl font-bold text-slate-700 text-center";
  const labelStyle = "text-lg text-center";

  const {
    numberOfCourses,
    averageScore,
    averageStepSkipped,
    averageTime,
    numberOfUsers,
    mostRepresentedUserType,
  } = stats as any;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 border-slate-200 lg:pr-6 lg:h-full">
      <div className={itemStyleSuccess}>
        <Icon name="CheckIcon" className="text-emerald-800 w-16 h-16 mb-8" />
        <p className={keyStyle}>{numberOfCourses}</p>
        <p className={labelStyle}>
          {t("stats.numberOfCourses", { count: numberOfCourses })}
        </p>
      </div>
      <div className={itemStyleSuccess}>
        <Icon name="CiTrophy" className="text-emerald-800 w-16 h-16 mb-8" />
        {/* pour l'instant c'est en valeur absolue mais ce serait bien qu'un jour ce soit en pourcentage car tous les quizz sont pas sur le même barème */}
        <p className={keyStyle}>{`${averageScore} pts`}</p>
        <p className={labelStyle}>{t("stats.averageScore")}</p>
      </div>
      <div className={itemStyleWarning}>
        <Icon name="XCircleIcon" className="text-orange-800 w-16 h-16 mb-8" />

        <p className={keyStyle}>{`${averageStepSkipped}`}</p>
        <p className={labelStyle}>
          {t("stats.averageStepSkipped", { count: averageStepSkipped })}
        </p>
      </div>

      <div className={itemStyleInfo}>
        <Icon name="ClockIcon" className="text-blue-800 w-16 h-16 mb-8" />

        <p className={keyStyle}>{`${averageTime}`}</p>
        <p className={labelStyle}>{t("stats.averageTime")}</p>
      </div>

      <div className={itemStyleInfo}>
        <Icon name="UsersIcon" className="text-blue-800 w-16 h-16 mb-8" />

        <p className={keyStyle}>{numberOfUsers}</p>
        <p className={labelStyle}>
          {t("stats.numberOfUsers", { count: numberOfUsers })}
        </p>
      </div>
      <div className={itemStyleInfo}>
        <Icon name="EyeIcon" className="text-blue-800 w-16 h-16 mb-8" />

        <p className={keyStyleSmall}>{`${mostRepresentedUserType}`}</p>
        <p className={labelStyle}>{t("stats.mostRepresentedUserType")}</p>
      </div>
    </div>
  );
}

export default StatsResult;
