import { IRadioBlockItem } from "common/components/RadioBlockItem/RadioBlockItem";

const boxStyle =
  "flex items-center mb-1 gap-2 border rounded-lg py-2 px-3 hover:border-secondary cursor-pointer";

export const getSpecialStyle = (selected?: boolean, isSpecial?: boolean) => {
  if (isSpecial) {
    return "border-secondary-400 text-secondary-400";
  }
  if (selected) {
    return "border-primary";
  }

  return "border-slate-200";
};

function CheckBoxBlockItem({
  title,
  onClick,
  selected,
  isSpecial,
}: IRadioBlockItem & { isSpecial?: boolean }) {
  const borderStyle = getSpecialStyle(selected, isSpecial);
  const inputStyle = isSpecial ? "text-secondary" : "text-primary";
  return (
    <div className={`${boxStyle} ${borderStyle}`} onClick={onClick}>
      <input
        type="checkbox"
        className={`h-4 w-4 rounded border-gray-300 focus:ring-0 focus:outline-none cursor-pointer bg-slate-200/60 ${inputStyle}`}
        checked={selected}
        onChange={onClick}
      />
      <span className="truncate">{title}</span>
    </div>
  );
}

export default CheckBoxBlockItem;
