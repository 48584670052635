import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useCallback, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import FormAddButton from "common/components/FormAddButton.tsx/FormAddButton";
import { BiMoveVertical } from "react-icons/bi";
import Button from "common/components/Button/Button";
import SortableList from "common/components/SortableList/SortableList";
import { FieldArray } from "react-final-form-arrays";
import "photoswipe/dist/photoswipe.css";
import MapField from "common/components/MapField/MapField";
import useAuth from "common/hooks/useAuth";
import PointSelect from "components/select/PointSelect/PointSelect";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import mapStyle from "common/components/MapField/mapStyle";
import useOrganization from "common/hooks/useOrganization";
import ThemeSelect from "components/select/ThemeSelect/ThemeSelect";

const defaultCenter = {
  lat: 43.6043,
  lng: 1.4437,
};

const markerIcon = {
  url: `/images/map-marker.png`,
  scaledSize: { equals: () => false, width: 35, height: 45.5 },
};

const validPosition = (position: any) =>
  Boolean(position?.lat && position?.lng);

const getPath = (points: any) => {
  const path: any[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const item of points) {
    if (validPosition(item.point)) {
      path.push({ lat: item.point.lat, lng: item.point.lng });
    } else {
      break;
    }
  }

  return path;
};

function DragHandle(props: any) {
  return (
    <BiMoveVertical
      {...props}
      className="w-4 h-4 cursor-move hover:text-primary border-none"
    />
  );
}

function PointItem(form: any, values: any, rest: any) {
  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div
        className="min-h-[3rem] min-w-[2rem] p-0 flex justify-center items-center cursor-pointer hover:text-primary bg-white rounded-sm border-slate-200"
        {...rest.dragProps}
      >
        <DragHandle />
      </div>

      <FormControl
        name={`${rest.name}.point`}
        type="custom"
        required
        containerStyle="flex-1 max-w-[calc(100%-7rem)]"
      >
        <PointSelect />
      </FormControl>

      <Button
        type="error-line"
        onClick={() => rest.fields.remove(rest.index)}
        icon="TrashIcon"
        className="min-h-[3rem] min-w-[3rem] w-12  p-0 flex justify-center"
        compact
      />
    </div>
  );
}

function CourseForm({ onSubmit, course, isFetching }: any) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const canUserManageCourseStatus =
    currentUser?.isSuperadmin ||
    currentUser?.isAdmin ||
    (currentUser?.isPro &&
      course?.id &&
      course?.author?.id === currentUser?.id &&
      currentUser?.canPublish === "submit_yes_update_yes");

  const initialValues = useMemo(
    () =>
      course?.id
        ? {
            ...course,
            theme: course.theme?.id,
            points: course.points.map((item: any) => ({
              id: item.id,
              point: {
                id: item.point.id,
                lat: item.point.point.lat,
                lng: item.point.point.lng,
              },
            })),
          }
        : {
            name: "",
            point: {
              lat: 43.6043,
              lng: 1.4437,
            },
            organization: organization?.id ?? currentUser?.organization,
          },
    [course, currentUser?.organization, organization?.id]
  );

  const onSubmitWrapper = async (values: any) => {
    const newValues = {
      ...values,
      points: values.points?.map((item: any) => ({ point: item.point.id })),
    };

    const res = await onSubmit(newValues);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  const onUpdateStatus = useCallback(
    async (newStatus: string, currentCourse: any) => {
      try {
        await onSubmit({ ...currentCourse, status: newStatus });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [onSubmit]
  );

  const onDraft = useCallback(
    (currentCourse: any) => {
      onUpdateStatus("draft", currentCourse);
    },
    [onUpdateStatus]
  );

  const onDisable = useCallback(
    (currentCourse: any) => {
      onUpdateStatus("disabled", currentCourse);
    },
    [onUpdateStatus]
  );

  const onPublish = useCallback(
    (currentCourse: any) => {
      onUpdateStatus("published", currentCourse);
    },
    [onUpdateStatus]
  );

  const difficultyLevelList = [
    { value: "easy", label: t("forms.easy") },
    { value: "medium", label: t("forms.medium") },
    { value: "hard", label: t("forms.hard") },
  ];

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        setPosition: (args: any, state: any, utils: any) => {
          const position = args[0];

          utils.changeValue(state, "point.lat", () => position.lat);
          utils.changeValue(state, "point.lng", () => position.lng);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setHelpPosition: ([_, geo], state, utils) => {
          utils.changeValue(
            state,
            "point.lat",
            () => geo && geo.location?.lat()
          );
          utils.changeValue(
            state,
            "point.lng",
            () => geo && geo.location?.lng()
          );
        },
      }}
      render={({ handleSubmit, values, form }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(course?.id ? "editItem.course" : "newItem.course")}
              subTitle={values.name}
              icon="GiPathDistance"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/courses")}
                  isChangingStatus={isFetching}
                  onUnpublish={() => onDraft(values)}
                  unpublishDisabled={
                    !values.status ||
                    ["draft"].includes(values.status) ||
                    !canUserManageCourseStatus
                  }
                  onExpirate={() => onDisable(values)}
                  expirateDisabled={
                    !values.status ||
                    ["disabled"].includes(values.status) ||
                    !canUserManageCourseStatus
                  }
                  onValidate={() => onPublish(values)}
                  validateDisabled={
                    !values.status ||
                    ["published"].includes(values.status) ||
                    !canUserManageCourseStatus
                  }
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="flex flex-col gap-2 w-full">
                <div className="white-box flex flex-col gap-2">
                  <SectionTitle
                    title={t("forms.mainInformation")}
                    icon="InformationCircleIcon"
                  />
                  <div className="flex flex-row w-full gap-8">
                    <div className="flex flex-col gap-2 md:w-1/3">
                      <FormControl
                        name="name"
                        title={t("common.name")}
                        required
                      />
                      <FormControl name="city" title={t("common.city")} />
                    </div>
                    <div className="flex flex-row gap-8 md:w-2/3">
                      <FormControl
                        name="description"
                        title={t("forms.description")}
                        type="richtext"
                        inputContainerStyle="flex-1"
                        containerStyle="flex w-full"
                        className="text-input"
                        required
                      />
                      <FormControl
                        type="image"
                        name="image"
                        titleKey={t("forms.picture")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full mt-2">
                <div className="white-box flex flex-col gap-2">
                  <div className="flex flex-row w-full gap-8">
                    <div className="flex flex-col gap-2 md:w-1/3">
                      <SectionTitle
                        title={t("forms.settings")}
                        icon="BsCardChecklist"
                      />
                      <FormControl
                        name="startingPoint"
                        title={t("forms.startingPoint")}
                        required
                      />
                      <FormControl
                        name="estimatedTime"
                        title={t("forms.estimatedTime")}
                        type="number"
                      />
                      <FormControl
                        name="totalDistance"
                        title={t("forms.totalDistance")}
                        type="number"
                      />
                      <FormControl
                        title={t("forms.theme")}
                        name="theme"
                        type="custom"
                      >
                        <ThemeSelect />
                      </FormControl>
                      <FormControl
                        type="select"
                        title={t("forms.difficultyLevel")}
                        name="difficulty"
                        options={difficultyLevelList}
                      />
                    </div>
                    <div className="flex flex-col md:w-2/3">
                      <MapField
                        position={{
                          lat: values.point?.lat || 0,
                          lng: values.point?.lng || 0,
                        }}
                        onChange={form.mutators.setPosition}
                        editable
                        mapPrefix="courseForm"
                        form={form}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full mt-2">
                <div className="white-box flex flex-col gap-2">
                  <div className="flex flex-row w-full gap-8">
                    <div className="flex flex-col gap-2 md:w-1/3">
                      <SectionTitle
                        title={t("forms.points")}
                        icon="ListBulletIcon"
                      />
                      <FieldArray name="points">
                        {({ fields }: any) => (
                          <div>
                            <SortableList
                              items={fields}
                              onMove={fields.move}
                              render={(rest) => PointItem(form, values, rest)}
                            />

                            <FormAddButton
                              label={t("newItem.pointLegend")}
                              onClick={() => fields.push({})}
                            />
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <div className="flex flex-col md:w-2/3">
                      <div className="flex-1 max-w-full rounded-lg max-h-[500px] overflow-hidden">
                        <GoogleMap
                          mapContainerStyle={{ width: "100%", height: 500 }}
                          center={
                            values.points?.length > 0 &&
                            validPosition(values.points[0].point)
                              ? {
                                  lat: values.points[0].point.lat,
                                  lng: values.points[0].point.lng,
                                }
                              : defaultCenter
                          }
                          zoom={14}
                          options={
                            {
                              styles:
                                mapStyle as unknown as google.maps.MapTypeStyle,
                              streetViewControl: false,
                              zoomControl: false,
                              mapTypeControl: false,
                            } as unknown as google.maps.MapOptions
                          }
                        >
                          {values?.points?.length > 0 &&
                            values?.points?.map((item: any, index: number) => {
                              return (
                                validPosition(item.point) && (
                                  <Marker
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`courseForm_readonly_${index}`}
                                    position={{
                                      lat: item.point.lat,
                                      lng: item.point.lng,
                                    }}
                                    icon={markerIcon}
                                  />
                                )
                              );
                            })}
                          {values?.points?.length > 0 && (
                            <Polyline
                              path={getPath(values.points)}
                              // options={{
                              //   strokeColor: "#ff2527",
                              //   strokeOpacity: 0.75,
                              //   strokeWeight: 2,
                              //   icons: [
                              //     {
                              //       icon: lineSymbol,
                              //       offset: "0",
                              //       repeat: "20px",
                              //     },
                              //   ],
                              // }}
                            />
                          )}
                        </GoogleMap>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/courses")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default CourseForm;
