import Button from "common/components/Button/Button";

import { Link } from "react-router-dom";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Course } from "types/Course";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import Status from "common/components/Status/Status";
import Icon from "components/Icon/Icon";
import truncateString from "common/utils/truncateString";
import InfoGroup, { Info } from "common/components/InfoGroup/InfoGroup";
import getFlattenText from "common/utils/getFlattenText";

function CourseItem({ item, onDelete, onNavigate, onDuplicate }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const {
    name,
    image,
    theme,
    difficulty,
    startingPoint,
    city,
    description,
    estimatedTime,
    totalDistance,
    author,
  } = item as Course;

  const canEditItem =
    currentUser?.isSuperadmin ||
    currentUser?.isAdmin ||
    (currentUser?.isPro && author?.id === currentUser?.id);
  const editLink = `/courses/edit/${item.id}`;

  const info: Info[] = [
    {
      name: estimatedTime ? `${estimatedTime} min` : "",
      infoKey: "forms.estimatedTime",
      icon: "ClockIcon",
    },
    {
      name: totalDistance ? `${totalDistance} km` : "",
      infoKey: "forms.totalDistance",
      icon: "GiPathDistance",
    },
    {
      nameKey: theme?.name ?? "",
      infoKey: "forms.theme",
      icon: "BookmarkIcon",
    },
    {
      nameKey: difficulty ? `forms.${difficulty}` : "",
      infoKey: "forms.difficultyLevel",
      icon: "ChartBarSquareIcon",
    },
  ];

  const authorInfo: Info[] = [
    {
      name: author.username,
      info: `${t("common.author")}: ${t(
        author?.role?.name === "SuperAdmin"
          ? "common.superadmin"
          : author?.role?.name === "Admin"
          ? "common.admin"
          : "common.pro"
      )}`,
      icon:
        author?.role?.name === "SuperAdmin"
          ? "GiStarFormation"
          : author?.role?.name === "Admin"
          ? "BsStarFill"
          : "BsStarHalf",
    },
  ];

  return (
    <div className="flex px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem ? "focus:pointer-events-none flex flex-1" : "flex flex-1"
        }
      >
        <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4">
          <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
            <div className="rounded-lg overflow-hidden w-full">
              <ImageComponent
                image={image ? ImageFromStrapiMedia(image)?.uri : undefined}
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="flex flex-col xl:flex-row w-full gap-4">
              <div className="flex flex-col flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {name}
                </h4>
              </div>
              <div className="flex justify-end items-start mb-2 lg:mb-0">
                <InfoGroup infoGroups={authorInfo} />
              </div>
            </div>

            <div className="responsive-flex-lg mt-1">
              <div className="text-sm flex">
                <div className="flex items-center mr-4">
                  <Icon name="BsPinMap" className="w-4 h-4 mr-1" />
                  <span className="font-bold">{startingPoint}</span>
                </div>
              </div>
              <div className="text-sm flex">
                <div className="flex items-center mr-4">
                  <Icon name="BuildingOffice2Icon" className="w-4 h-4 mr-1" />
                  <span>{city}</span>
                </div>
              </div>
            </div>

            {/* foot */}
            <div className="flex flex-col gap-2 pt-1 mt-2 border-t border-slate-200">
              <div className="responsive-flex-lg mt-1">
                <InfoGroup infoGroups={info} />
              </div>
              <div className="text-sm text-gray-500 ">
                {truncateString(getFlattenText(description), 200)}
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l lg:pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        {item.status ? <Status status={item.status} /> : <div />}
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="DocumentDuplicateIcon"
                compact
                onClick={() => onDuplicate(item.id)}
                disabled={!canEditItem}
              />
              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseItem;
