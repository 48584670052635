import { capitalize } from "lodash";

const getWording = (plural?: boolean, capital?: boolean) => {
  if (plural) {
    return capital ? capitalize("points") : "points";
  }
  return capital ? capitalize("point") : "point";
};

export default getWording;
