import classNames from "classnames";

export interface ITabBlockItem {
  id: string;
  icon?: any;
  selected?: boolean;
  onClick?: () => void;
  // new
  name: string;
  count?: number | string;
  color?: string;
}

const hover = "hover:bg-slate-200";
const white = "text-white";
const currentColors: any = {
  yellow: `bg-yellow-500 ${white} border-yellow-500 `,
  blue: `bg-blue-500 text-white ${white} border-blue-500`,
  green: `bg-emerald-500 text-white ${white} border-emerald-500`,
  gray: `bg-gray-500 text-white ${white} border-gray-500`,
  slate: `bg-slate-500 text-white ${white} border-slate-500`,
  primary: `bg-primary-500 text-white ${white} border-primary-500 `,
  secondary: `bg-secondary text-white ${white} border-secondary`,
};

const notCurrentColors: any = {
  yellow: `border-yellow-500 text-yellow-600 ${hover}`,
  blue: `border-blue-500 text-blue-600 ${hover}`,
  green: `border-emerald-500 text-emerald-600 ${hover}`,
  gray: `border-gray-500 text-gray-600 ${hover}`,
  slate: `border-slate-500 text-slate-600 ${hover}`,
  primary: `border-primary-500 text-primary-600 ${hover}`,
  secondary: `border-secondary-500 text-secondary-600 ${hover}`,
};

const tagColors: any = {
  yellow: "bg-yellow-100 text-yellow-600 group-hover:bg-yellow-100",
  blue: "bg-blue-100 text-blue-600 group-hover:bg-blue-100",
  green: "bg-emerald-100 text-emerald-600 group-hover:bg-emerald-100",
  gray: "bg-gray-100 text-gray-600 group-hover:bg-gray-100",
  slate: "bg-slate-200 text-slate-600 group-hover:bg-slate-150",
  primary: "bg-primary-100 text-primary-600 group-hover:bg-primary-100",
  secondary: "bg-secondary-200 text-secondary-600 group-hover:bg-secondary-100",
};
const tagHoverColors: any = {
  yellow: "group-hover:bg-yellow-100 group-hover:text-yellow-600",
  blue: "group-hover:bg-blue-100 group-hover:text-blue-600",
  green: "group-hover:bg-emerald-100 group-hover:text-emerald-600",
  gray: "group-hover:bg-gray-100 group-hover:text-gray-600",
  slate: "group-hover:bg-slate-200 group-hover:text-slate-600",
  primary: "group-hover:bg-primary-100 group-hover:text-primary-600",
  secondary: "group-hover:bg-secondary-200 group-hover:text-secondary-600",
};
function TabBlockItem({
  id,
  icon,
  selected,
  onClick,
  // new
  name,
  count,
  color = "primary",
}: ITabBlockItem) {
  const IconComponent = icon;
  return (
    <div
      key={id}
      className={classNames(
        selected
          ? currentColors[color] || currentColors.slate
          : `text-primary ${notCurrentColors[color] || notCurrentColors.slate}`,
        "border-b-2 cursor-pointer whitespace-nowrap flex items-center h-[33px] px-4 flex-1 md:flex-none xl:px-4 bg-b-2 font-semibold text-sm group rounded-t-lg transition-all"
      )}
      onClick={onClick}
    >
      <IconComponent className="w-full md:w-4 h-6 md:h-4 mr-1 xl:mr-2" />
      <span className="hidden xl:flex">{name}</span>

      {count ? (
        <span
          className={classNames(
            tagColors[color] || tagColors.slate,
            /* selected
              ? tagColors[color] || tagColors.slate
              : "bg-slate-200 text-slate-500", */ `hidden ml-1 xl:ml-3 py-0.5 px-1 xl:px-2 rounded-full text-xxs font-medium md:inline-block ${
              tagHoverColors[color] || tagHoverColors.slate
            }`
          )}
        >
          {count}
        </span>
      ) : null}
    </div>
  );
}

export default TabBlockItem;
