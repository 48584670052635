import { IRadioBlockItemData } from "common/components/RadioBlock/RadioBlock";

const rolesData: IRadioBlockItemData[] = [
  {
    id: 5,
    icon: "BsStarHalf",
    value: "Pro",
    titleKey: "roles.Pro",
    subTitleKey: "roles.ProSubtitle",
    containerSelStyle: "bg-blue-400 border-blue-400 shadow-lg shadow-blue-200",
    iconStyle: "text-white",
    iconContainerStyle: "bg-blue-400",
    iconSelStyle: "text-blue-400",
    iconContainerSelStyle: "bg-white",
  },
  {
    id: 4,
    icon: "BsStarFill",
    value: "Administrateur",
    titleKey: "roles.Administrateur",
    subTitleKey: "roles.AdministrateurSubtitle",
    containerSelStyle: "bg-blue-700 border-blue-700 shadow-lg shadow-blue-200",
    iconStyle: "text-white",
    iconContainerStyle: "bg-blue-700",
    iconSelStyle: "text-blue-700",
    iconContainerSelStyle: "bg-white",
  },
];

export default rolesData;
