import { isArray, isEmpty } from "lodash";
import { IRadioBlockItem } from "common/components/RadioBlockItem/RadioBlockItem";

export type IRadioBlockItemData = IRadioBlockItem & { value: string };

type IValue = number | number[];
interface IRadioBlock {
  items: IRadioBlockItemData[];
  onChange?: (id: IValue) => void;
  multiple?: boolean;
  value?: IValue;
  Component: React.ElementType;
  horizontal?: boolean;
  noGap?: boolean;
}

const getSelected = (itemValue: number, value?: IValue, multiple?: boolean) => {
  if (!multiple && !value) {
    return isEmpty(itemValue);
  }
  if (!multiple) {
    return itemValue === value;
  }

  return isArray(value) && value.includes(itemValue);
};

function RadioBlock({
  items,
  value,
  onChange,
  multiple,
  Component,
  horizontal = false,
  noGap = false,
}: IRadioBlock) {
  const onClick = (itemValue: number) => {
    if (!onChange) {
      return;
    }
    if (!multiple) {
      onChange(itemValue);
      return;
    }

    if (isArray(value) && value?.includes(itemValue)) {
      onChange(value.filter((v) => v !== itemValue));
      return;
    }

    if (isArray(value)) {
      onChange([...value, itemValue]);
    }
  };

  return (
    <div
      className={`${horizontal ? "flex flex-wrap w-full" : "flex flex-col"} ${
        !noGap && "gap-3 w-full"
      }`}
    >
      {items.map((item: IRadioBlockItemData) => (
        <Component
          onClick={() => onClick(item.id)}
          selected={getSelected(item.id, value, multiple)}
          {...item}
        />
      ))}
    </div>
  );
}

export default RadioBlock;
