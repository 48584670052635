import { BrowserRouter as Router } from "react-router-dom";
import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";

import AppLayout from "./pages/AppLayout/AppLayout";

import AppProvider from "./AppProvider";
import "./i18n";
import "./App.css";

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(isBetween);

export default function App() {
  return (
    <Router>
      <AppProvider>
        <AppLayout />
      </AppProvider>
    </Router>
  );
}
