import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FormControl from "common/components/FormControl/FormControl";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";
import Button from "common/components/Button/Button";

import LoginHeader from "./LoginHeader";
import "./auth.css";

function Login() {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [error, setError] = useState<string>("");

  const {
    user,
    signin,
    isFetching,
    isSignInError: isFetchingError,
    isUnauthorized,
  } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const handleLogin = async ({ email, password }: any) => {
    const isUserLogged = await signin(email, password);
    if (
      isUserLogged &&
      typeof isUserLogged !== "boolean" &&
      "id" in isUserLogged
    ) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (isFetchingError) {
      setError(t("common.invalidCredentials"));
    }
  }, [isFetchingError, t]);

  useEffect(() => {
    if (isUnauthorized) {
      setError(t("common.unauthorizedUser"));
    }
  }, [isUnauthorized, t]);

  if (user) {
    return null;
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <LoginHeader />
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="login-box">
          <div className="text-xl text-center font-bold text-primary mb-4">
            {t("common.login")}
          </div>
          <Form
            onSubmit={handleLogin}
            render={({ handleSubmit }) => (
              <form className="flex flex-col gap-3" noValidate>
                <FormControl
                  name="email"
                  title={t("common.email")}
                  onChange={() => setError("")}
                />
                <FormControl
                  type="password"
                  name="password"
                  onChange={() => setError("")}
                  title={t("common.password")}
                />
                {Boolean(error) && (
                  <div className="flex justify-center text-red-500">
                    {error}
                  </div>
                )}
                <div>
                  <Button
                    type="primary"
                    title={t("common.login")}
                    className="w-full justify-center py-4"
                    loading={isFetching}
                    onClick={handleSubmit}
                  />
                </div>
                <div className="flex justify-center">
                  <Link
                    className="font-bold text-sm text-slate-500 cursor-pointer hover:text-secondary"
                    to="/forgot-password"
                  >
                    {t("common.forgottenPassword")}
                  </Link>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
