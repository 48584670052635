import { useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FormControl from "common/components/FormControl/FormControl";
import useAuth from "common/hooks/useAuth";
import useRouter from "common/hooks/use-router";
import Button from "common/components/Button/Button";

import { isPasswordCorrect } from "common/components/Password/Password";
import LoginHeader from "./LoginHeader";
import "./auth.css";

function ResetPassword() {
  const { t } = useTranslation();
  const [emailDone, setEmailDone] = useState(false);
  const router = useRouter();
  const code = router.query.code as string;

  const { resetPassword, isFetching } = useAuth();

  const sendPassword = async (data: any) => {
    try {
      await resetPassword(data.password, code);
      setEmailDone(true);
    } catch (error) {
      console.error(error);
    }
  };

  const checkPassword = (value: string) =>
    isPasswordCorrect(value) ? undefined : t("common.passwordTooWeak");

  const validatePasswords = (values: any) => {
    if (values.password !== values.confirmPassword) {
      return { confirmPassword: t("common.resetPasswordError") };
    }
    return {};
  };

  const content = emailDone ? (
    <div className="text text-center text-slate-500 mb-4">
      {t("common.resetPasswordDone")}
      <div className="mt-5">
        <Link
          className="font-bold text-sm text-slate-500 cursor-pointer hover:text-secondary"
          to="/"
        >
          {t("common.login")}
        </Link>
      </div>
    </div>
  ) : (
    <Form
      onSubmit={sendPassword}
      validate={validatePasswords}
      render={({ handleSubmit }) => (
        <form
          className="flex flex-col gap-3"
          onSubmit={handleSubmit}
          noValidate
        >
          <FormControl
            type="jpassword"
            name="password"
            titleKey={t("forms.password")}
            helpText={t("common.passwordInstruction")}
            required
            validate={checkPassword}
            disabled={isFetching}
          />

          <FormControl
            name="confirmPassword"
            title={t("forms.confirmPassword")}
            disabled={isFetching}
            type="password"
          />
          <div>
            <Button
              type="primary"
              title={t("common.sendEmail")}
              className="w-full justify-center py-4"
              loading={isFetching}
              onClick={handleSubmit}
            />
          </div>
        </form>
      )}
    />
  );

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <LoginHeader />
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="login-box">
          <div className="text-xl text-center font-bold text-primary mb-6">
            {t("common.resetPasswordTitle")}
          </div>
          {content}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
