function PollutionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="408"
      height="500"
      version="1"
      viewBox="0 0 408 400"
      fill="currentColor"
      {...props}
    >
      <path
        d="M68.3,249.6c-7.8-3.5-16.4-5.9-23.3-10.8c-17.6-12.6-23.6-36.5-15.5-56.9c8.1-20.2,29.3-33.1,50.4-30.7
	c22.2,2.5,39.8,19,43.3,40.6c4.5,26.8-12.7,51.6-39.3,56.7c-0.8,0.1-1.4,0.5-2.2,0.9C77.5,249.6,72.9,249.6,68.3,249.6z M75.4,180.7
	c-10.8-0.1-19.7,8.7-19.8,19.6c-0.1,10.8,8.7,19.8,19.4,19.8c10.8,0.1,19.7-8.7,19.8-19.6C95,189.7,86.2,180.8,75.4,180.7z"
      />
      <path
        d="M198.3,249.6c-7.8-3.5-16.4-5.9-23.3-10.8c-17.6-12.6-23.6-36.5-15.5-56.9c8.1-20.2,29.3-33.1,50.4-30.7
	c22.2,2.5,39.8,19,43.3,40.6c4.5,26.8-12.7,51.6-39.3,56.7c-0.8,0.1-1.4,0.5-2.2,0.9C207.5,249.6,202.9,249.6,198.3,249.6z
	 M205.4,180.7c-10.8-0.1-19.7,8.7-19.8,19.6c-0.1,10.8,8.7,19.8,19.4,19.8c10.8,0.1,19.7-8.7,19.8-19.6
	C225,189.7,216.2,180.8,205.4,180.7z"
      />
      <path
        d="M328.3,249.6c-7.8-3.5-16.4-5.9-23.3-10.8c-17.6-12.6-23.6-36.5-15.5-56.9c8.1-20.2,29.3-33.1,50.4-30.7
	c22.2,2.5,39.8,19,43.3,40.6c4.5,26.8-12.7,51.6-39.3,56.7c-0.8,0.1-1.4,0.5-2.2,0.9C337.5,249.6,332.9,249.6,328.3,249.6z
	 M335.4,180.7c-10.8-0.1-19.7,8.7-19.8,19.6c-0.1,10.8,8.7,19.8,19.4,19.8c10.8,0.1,19.7-8.7,19.8-19.6
	C354.9,189.7,346.1,180.8,335.4,180.7z"
      />
    </svg>
  );
}

export default PollutionIcon;
