import React, { useContext } from "react";
import AlertsContext from "common/providers/alerts";
import Alert from "./Alert";

function Alerts() {
  const { alerts, clearAlert } = useContext(AlertsContext);

  return (
    <div
      className={`${
        alerts.length > 0 && "mt-2"
      } fixed left-[50%] translate-x-[-50%] z-[10000]`}
    >
      {alerts.length > 0 &&
        alerts.map((alert: any) => (
          <Alert key={`${alert.id}`} alert={alert} clearAlert={clearAlert} />
        ))}
    </div>
  );
}

export default Alerts;
