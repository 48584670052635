import React, { useState, useCallback } from "react";

const memoryStorage: any = {};

const useMemoryState = <T>(
  initialState: any,
  key: string
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState(memoryStorage[key] ?? initialState);

  const memorySetState: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (newState: T | ((prevState: T) => T)) => {
      setState((oldState: T) => {
        const newStateValue =
          typeof newState === "function"
            ? (newState as (prevState: T) => T)(oldState)
            : newState;

        memoryStorage[key] = newStateValue;
        // console.log({ memoryStorage });

        return newStateValue;
      });
    },
    [key]
  );

  return [state, memorySetState];
};

export default useMemoryState;
