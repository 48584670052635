/* eslint-disable no-console */
import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import fetchJSON from "common/utils/fetchJSON";
import { CoursesNumber } from "types/CoursesNumber";
import useOrganization from "common/hooks/useOrganization";

type CourseProviderProps = {
  coursesNumber: CoursesNumber;
  refreshCoursesNumber: (organizationId: any) => Promise<CoursesNumber>;
  isFetching: boolean;
};

export const CourseContext = createContext<CourseProviderProps>(
  {} as CourseProviderProps
);

type Props = {
  children: React.ReactNode;
};

const defaultCoursesNumber: CoursesNumber = {
  all: 0,
  draft: 0,
  published: 0,
  disabled: 0,
};

export function CourseProvider({ children }: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const [coursesNumber, setCoursesNumber] =
    useState<CoursesNumber>(defaultCoursesNumber);
  const { organization } = useOrganization();

  const refreshCoursesNumber = useCallback(
    async (organizationId: any) => {
      try {
        setIsFetching(true);

        if (organizationId) {
          const res = await fetchJSON({
            url: `courses/private/data/number/${organizationId}`,
            method: "GET",
          });
          if (res) {
            setCoursesNumber(
              res
                ? {
                    ...res,
                  }
                : undefined
            );

            return res;
          }
        } else {
          setCoursesNumber(defaultCoursesNumber);
        }

        return false;
      } catch (e) {
        console.log(e);
        return false;
      } finally {
        setIsFetching(false);
      }
    },
    [setCoursesNumber]
  ); // pushToken

  useEffect(() => {
    const firstLoad = async () => {
      await refreshCoursesNumber(organization?.id);
    };

    firstLoad();
  }, [organization?.id, refreshCoursesNumber]);

  const value: CourseProviderProps = useMemo(
    () => ({
      coursesNumber,
      refreshCoursesNumber,
      isFetching,
    }),
    [coursesNumber, isFetching, refreshCoursesNumber]
  );

  return (
    <CourseContext.Provider value={value}>{children}</CourseContext.Provider>
  );
}
