import { useTranslation } from "react-i18next";
import Input from "../FormInput/FormInput";

const isUppercaseMandatory = true;
const isLowercaseMandatory = true;
const isNumberMandatory = true;
const isSymbolMandatory = true;
const minimumLength = 8;

export const isMailCorrect = (mail: string | undefined) => {
  if (!mail) return false;

  const isCorrect = mail?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return isCorrect;
};

export const isPasswordCorrect = (password: string | undefined) => {
  if (!password) return false;

  const isUppercase = password.match(/[A-Z]/g);
  const isLowercase = password.match(/[a-z]/g);
  const isNumber = password.match(/[0-9]/g);
  const isSymbol = password.match(/[^\w\s]/g);
  let isError = false;

  if (isUppercaseMandatory && isUppercase === null) isError = true;
  if (isLowercaseMandatory && isLowercase === null) isError = true;
  if (isNumberMandatory && isNumber === null) isError = true;
  if (isSymbolMandatory && isSymbol === null) isError = true;
  if (password.length < minimumLength) isError = true;

  return !isError;
};

const getStrength = (password: string | undefined) => {
  if (!password) return 0;

  const isUppercase = password.match(/[A-Z]/g);
  const isLowercase = password.match(/[a-z]/g);
  const isNumber = password.match(/[0-9]/g);
  const isSymbol = password.match(/[^\w\s]/g);
  let passwordStrength: any = 0;

  if (isUppercase && isLowercase && isNumber && isSymbol) {
    passwordStrength = 6.49;
  } else if (isUppercase && isLowercase && isNumber) {
    passwordStrength = 5.95;
  } else if (isUppercase && isLowercase && isSymbol) {
    passwordStrength = 6.1;
  } else if (isUppercase && isLowercase) {
    passwordStrength = 5.7;
  } else if ((isUppercase || isLowercase) && isNumber) {
    passwordStrength = 5.17;
  } else if (isUppercase || isLowercase) {
    passwordStrength = 4.7;
  } else if (isNumber) {
    passwordStrength = 3.32;
  }

  // If something is missing, keep the score low
  if (!isPasswordCorrect(password)) return 5;

  return Math.round(parseFloat(passwordStrength) * password.length);
};

const getPasswordColor = (strength: number, t: any) => {
  if (strength <= 49) {
    return {
      background: "darkred",
      text: t("common.password1"),
      textColor: "white",
    };
  }
  if (strength <= 65) {
    return {
      background: "tomato",
      text: t("common.password2"),
      textColor: "white",
    };
  }
  if (strength <= 78) {
    return {
      background: "orange",
      text: t("common.password3"),
      textColor: "white",
    };
  }
  if (strength <= 82) {
    return {
      background: "goldenrod",
      text: t("common.password4"),
      textColor: "white",
    };
  }
  if (strength <= 104) {
    return {
      background: "yellowgreen",
      text: t("common.password5"),
      textColor: "white",
    };
  }

  return {
    background: "green",
    text: t("common.password6"),
    textColor: "white",
  };
};

type Props = {
  onChange?: (value: string) => any;
  value?: string;
};

function Password({ onChange, value }: Props) {
  const strength = getStrength(value);
  const { t } = useTranslation();
  const { background, text, textColor } = getPasswordColor(strength, t);

  return (
    <div>
      <Input type="password" onChange={onChange} />

      {value !== "" && (
        <div
          style={{
            marginTop: "10px",
            backgroundColor: background,
            width: `${strength > 100 ? 100 : strength}%`,
            padding: "0px 5px",
            borderRadius: 8,
            minWidth: 100,
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          <span style={{ color: textColor }}>{text}</span>
        </div>
      )}
    </div>
  );
}

export default Password;
