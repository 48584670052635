import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import FormAddButton from "common/components/FormAddButton.tsx/FormAddButton";
import { BiMoveVertical } from "react-icons/bi";
import Button from "common/components/Button/Button";
import SortableList from "common/components/SortableList/SortableList";
import { FieldArray } from "react-final-form-arrays";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import ThreadGallery from "common/components/ThreadGallery/ThreadGallery";
import "photoswipe/dist/photoswipe.css";
import MapField from "common/components/MapField/MapField";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";

function DragHandle(props: any) {
  return (
    <BiMoveVertical
      {...props}
      className="w-4 h-4 cursor-move hover:text-primary border-none"
    />
  );
}

function AnswerItem(form: any, values: any, quizIndex: any, rest: any) {
  const { t } = useTranslation();

  const toggleFalseAnswers = () => {
    values.quiz[quizIndex].answers?.map(
      (_answerItem: any, answerIndex: number) => {
        if (answerIndex !== rest.index) {
          form.mutators.setFieldValue({
            field: `quiz[${quizIndex}].answers[${answerIndex}].validAnswer`,
            value: false,
          });
        }
        return null;
      }
    );
  };

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-500 py-2 pl-3 pr-1 bg-slate-400">
      <div
        className="min-h-[3rem] min-w-[2rem] p-0 flex justify-center items-center cursor-pointer hover:text-primary bg-white rounded-sm border-slate-200"
        {...rest.dragProps}
      >
        <DragHandle />
      </div>
      <div className="flex flex-col w-full gap-3">
        <FormControl
          name={`${rest.name}.label`}
          titleKey={t("forms.answerLabel")}
          containerStyle="w-full text-white text-xs gap-2"
          required
        />

        <FormControl
          type="custom"
          name={`${rest.name}.validAnswer`}
          containerStyle="text-white"
          onChange={() => {
            toggleFalseAnswers();
          }}
        >
          <ToggleSwitch rightLabel={t("forms.validAnswer")} />
        </FormControl>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] min-w-[3rem] w-12  p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function QuizItem(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div
        className="min-h-[3rem] min-w-[2rem] p-0 flex justify-center items-center cursor-pointer hover:text-primary bg-white rounded-sm border-slate-200"
        {...rest.dragProps}
      >
        <DragHandle />
      </div>

      <div className="flex flex-col w-full gap-3 text-xs">
        <FormControl
          name={`${rest.name}.question`}
          titleKey={t("forms.question")}
          containerStyle="w-full text-xs"
          required
        />

        <FormControl
          name={`${rest.name}.points`}
          titleKey={t("forms.questionPoints")}
          containerStyle="w-full text-xs "
          required
        />

        <div className="text-xs gap-2">
          <SectionTitle title={t("forms.answers")} icon="BsCardChecklist" />
          <div className="flex flex-col gap-2">
            <FieldArray name={`${rest.name}.answers`}>
              {({ fields: f }: any) => (
                <div>
                  <SortableList
                    items={f}
                    onMove={f.move}
                    render={(rest2) =>
                      AnswerItem(form, values, rest.index, rest2)
                    }
                  />

                  <FormAddButton
                    label={t("newItem.answer")}
                    onClick={() =>
                      f.push({
                        label: "",
                        validAnswer: false,
                        random: Math.floor(Math.random() * 10000),
                      })
                    }
                  />
                </div>
              )}
            </FieldArray>
          </div>
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] min-w-[3rem] w-12  p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PointForm({ onSubmit, point }: any) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const initialValues = useMemo(
    () =>
      point?.id
        ? {
            ...point,
          }
        : {
            name: "",
            point: organization?.defaultMapPoint
              ? {
                  lat: (organization?.defaultMapPoint as any)?.lat,
                  lng: (organization?.defaultMapPoint as any)?.lng,
                }
              : {
                  lat: 43.6043,
                  lng: 1.4437,
                },
            organization: organization?.id ?? currentUser?.organization,
          },
    [
      currentUser?.organization,
      organization?.defaultMapPoint,
      organization?.id,
      point,
    ]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        setPosition: (args: any, state: any, utils: any) => {
          const position = args[0];

          utils.changeValue(state, "point.lat", () => position.lat);
          utils.changeValue(state, "point.lng", () => position.lng);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setHelpPosition: ([_, geo], state, utils) => {
          utils.changeValue(
            state,
            "point.lat",
            () => geo && geo.location?.lat()
          );
          utils.changeValue(
            state,
            "point.lng",
            () => geo && geo.location?.lng()
          );
        },
      }}
      render={({ handleSubmit, values, form }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(point?.id ? "editItem.point" : "newItem.point")}
              subTitle={values.name}
              icon="BsPinMap"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/points")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="flex flex-col gap-2 w-full">
                <div className="white-box flex flex-col gap-2">
                  <SectionTitle
                    title={t("forms.settings")}
                    icon="InformationCircleIcon"
                  />
                  <FormControl name="name" title={t("common.name")} required />

                  <div className="flex w-full gap-2">
                    <MapField
                      position={{
                        lat: values.point?.lat || 0,
                        lng: values.point?.lng || 0,
                      }}
                      onChange={form.mutators.setPosition}
                      editable
                      mapPrefix="pointForm"
                      form={form}
                    />

                    {/* <div className="flex flex-col gap-2">
                      <FormControl
                        name="point.lat"
                        title={t("forms.lat")}
                        required
                        onChange={form.mutators.setPosition}
                      />
                      <FormControl
                        name="point.lng"
                        title={t("forms.lng")}
                        required
                        onChange={form.mutators.setPosition}
                      />
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="responsive-flex-lg gap-2 mt-2">
                <div className="flex flex-col gap-2 w-full">
                  <div className="white-box flex flex-col gap-2">
                    <SectionTitle
                      title={t("forms.gamification")}
                      icon="PuzzlePieceIcon"
                    />
                    <FormControl
                      name="maxTime"
                      title={t("forms.maxTime")}
                      type="number"
                    />
                    <FormControl
                      name="successRadius"
                      title={t("forms.successRadius")}
                      type="number"
                      required
                    />
                    <FormControl
                      name="hintRadius"
                      title={t("forms.hintRadius")}
                      type="number"
                      required
                    />
                    <FormControl
                      name="hint"
                      titleKey={t("forms.hint")}
                      type="textarea"
                      labelIcon="BsTextIndentLeft"
                      inputContainerStyle="flex-1"
                      containerStyle="flex w-full"
                      className="text-input"
                      required
                    />
                    <SectionTitle
                      title={t("forms.quiz")}
                      icon="BsCardChecklist"
                      className="mt-4"
                    />
                    <div className="flex flex-col gap-2">
                      {values.quiz?.length > 0 && (
                        <FormControl type="custom" name="displayQuizBefore">
                          <ToggleSwitch
                            rightLabel={t("forms.displayQuizBefore")}
                            legende={t("forms.displayQuizBeforeLegende")}
                          />
                        </FormControl>
                      )}
                      <FieldArray name="quiz">
                        {({ fields }: any) => (
                          <div>
                            <SortableList
                              items={fields}
                              onMove={fields.move}
                              render={(rest) => QuizItem(form, values, rest)}
                            />

                            <FormAddButton
                              label={t("newItem.quiz")}
                              onClick={() =>
                                fields.push({
                                  name: "",
                                  points: 1,
                                  random: Math.floor(Math.random() * 10000),
                                })
                              }
                            />
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <div className="white-box flex flex-col gap-2">
                    <SectionTitle
                      title={t("forms.culture")}
                      icon="BookOpenIcon"
                    />
                    <div className="w-full">
                      {/* todo : j'ai mis "file" plutot que "image" car ça gère mieux le multiple, mais
                      il faut rajouter une propriété qui fait apparaitre l'image */}
                      <FormControl
                        type="file"
                        name="images"
                        titleKey={t("forms.pictures")}
                      />
                    </div>
                    <div className="w-full">
                      <ThreadGallery documents={values.images} />
                    </div>
                    <FormControl
                      name="description"
                      titleKey={t("forms.description")}
                      type="richtext"
                      labelIcon="BsTextIndentLeft"
                      inputContainerStyle="flex-1"
                      containerStyle="flex w-full"
                      className="text-input"
                      required
                    />
                    <FormControl
                      name="youtube"
                      title={t("forms.youtube")}
                      type="player"
                    />
                    <FormControl
                      name="sound"
                      title={t("forms.sound")}
                      type="player"
                    />
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/points")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default PointForm;
