import DebounceInput from "common/components/DebounceInput/DebounceInput";
import StickyWrapper from "common/components/StickyWrapper/StickyWrapper";

function StatsCustomHeader({ columns, filters, setFilter }: any) {
  return (
    <div className="flex flex-col relative">
      <StickyWrapper isFilters disabledOnMobile>
        <div
          className=" flex w-full
      justify-center px-4 py-3 border border-t-0 border-slate-150 rounded-b-lg mb-2 z-10 bg-slate-100 gap-2"
        >
          {columns.map((column: any, index: any) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`header-${index}`}
              className="w-full flex flex-col gap-4 lg:flex-row lg:flex-wrap align-center"
            >
              <div
                className={`py-0 flex flex-col ${column.columnClasses} maxLg:w-full`}
              >
                {column.filterName &&
                  (column.filterComponent ? (
                    <column.filterComponent
                      placeholder={column.Header}
                      value={filters[column.filterName]}
                      data-cy={column.filterName}
                      onChange={(value: any) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        column.customFormat
                          ? setFilter(
                              column.filterName,
                              column.customFormat(value)
                            )
                          : setFilter(column.filterName, value);
                      }}
                    />
                  ) : (
                    <DebounceInput
                      className="text-input-filter"
                      placeholder={column.Header}
                      value={filters[column.filterName] as string}
                      // TODO trouver un moyen de faire revenir à la page 0
                      onChange={(value) => setFilter(column.filterName, value)}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </StickyWrapper>
    </div>
  );
}

export default StatsCustomHeader;
