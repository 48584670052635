import { Dots } from "react-activity";
import { useTranslation } from "react-i18next";

function PageLoading({ customStyles = null, error }: any) {
  const { t } = useTranslation();
  return (
    <div
      className={
        customStyles ||
        "fixed left-[20rem] w-[100%] h-[calc(100%-9rem)] max-w-[calc(100%-22rem)] mx-auto flex justify-center bg-slate-100/80 z-50"
      }
    >
      <div className="flex justify-center align-center">
        {error ? (
          "Une erreur serveur s'est produite. Veuillez nous excuser du dérangement."
        ) : (
          <>
            <Dots />
            <span className="ml-3">{t("common.loading")}</span>
          </>
        )}
      </div>
    </div>
  );
}

export default PageLoading;
