import Button from "common/components/Button/Button";
import { Link } from "react-router-dom";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { UserType } from "types/UserType";

function UserTypeItem({ item, onDelete, onNavigate }: any) {
  const { user: currentUser } = useAuth();
  const canEditItem = currentUser?.isSuperadmin;

  const { name } = item as UserType;

  const editLink = `/usertypes/edit/${item.id}`;

  const { t } = useTranslation();

  return (
    <div className="flex px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem ? "pointer-events-none flex flex-1" : "flex flex-1"
        }
      >
        <div className="flex flex-col flex-1 justify-between pl-4 pr-6 mb-2">
          <div className="flex mt-4 mb-2 gap-3">
            <div className="flex-1">
              <h4 className="text-xl font-bold text-primary leading-4">
                {name}
              </h4>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-end items-end text-xs lg:border-l lg:pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />

              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserTypeItem;
