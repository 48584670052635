import Login from "pages/Auth/Login";
import Page404 from "pages/Page404";
import Home from "pages/Home/Home";
import Organizations from "pages/Organizations/Organizations";
import Organization from "pages/Organizations/Organization";
import Users from "pages/Users/Users";
import User from "pages/Users/User";
import MyAccount from "pages/Users/MyAccount";
import Support from "pages/Support/Support";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import Points from "pages/Points/Points";
import Point from "pages/Points/Point";
import Themes from "pages/Theme/Themes";
import Theme from "pages/Theme/Theme";
import UserTypes from "pages/UserType/UserTypes";
import UserType from "pages/UserType/UserType";
import Courses from "pages/Courses/Courses";
import Course from "pages/Courses/Course";
import Stats from "pages/Stats/Stats";

const managers = ["SuperAdmin", "Administrateur"];
const pros = ["SuperAdmin", "Administrateur", "Pro"];

const routes = [
  { path: "/usertypes", roles: pros, Component: UserTypes },
  { path: "/usertypes/add", roles: managers, Component: UserType },
  { path: "/usertypes/edit/:id", roles: managers, Component: UserType },
  { path: "/themes", roles: pros, Component: Themes },
  { path: "/themes/add", roles: managers, Component: Theme },
  { path: "/themes/edit/:id", roles: managers, Component: Theme },
  { path: "/points", roles: pros, Component: Points },
  { path: "/points/add", roles: pros, Component: Point },
  { path: "/points/edit/:id", roles: pros, Component: Point },
  { path: "/points/duplicate/:id", roles: pros, Component: Point },
  { path: "/stats", roles: pros, Component: Stats },
  { path: "/courses", roles: pros, Component: Courses },
  { path: "/courses/add", roles: pros, Component: Course },
  { path: "/courses/edit/:id", roles: pros, Component: Course },
  { path: "/courses/duplicate/:id", roles: pros, Component: Course },
  { path: "/users", roles: managers, Component: Users },
  { path: "/users/add", roles: managers, Component: User },
  { path: "/users/edit/:id", roles: managers, Component: User },
  { path: "/my-account", roles: pros, Component: MyAccount },
  { path: "/organizations", roles: ["SuperAdmin"], Component: Organizations },
  {
    path: "/organizations/add",
    roles: ["SuperAdmin"],
    Component: Organization,
  },
  {
    path: "/organizations/edit/:id",
    roles: ["SuperAdmin"],
    Component: Organization,
  },
  {
    path: "my-organization",
    roles: ["SuperAdmin", "Administrateur"],
    Component: Organization,
  },
  { path: "/support", roles: "all", Component: Support },
  { path: "/", roles: "all", Component: Home },
  { path: "*", roles: "all", exact: false, Component: Page404 },
];

export const loginRoutes = [
  { path: "/login", roles: "all", Component: Login },
  { path: "/forgot-password", roles: "all", Component: ForgotPassword },
  { path: "/reset-password", roles: "all", Component: ResetPassword },
  { path: "*", roles: "all", Component: Login },
];

export default routes;
