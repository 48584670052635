import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import StickyWrapper from "../StickyWrapper/StickyWrapper";

interface IHeader {
  title: string;
  subTitle?: string;
  btnTitle?: string;
  btnSubtitle?: string;
  Icon: any;
  addLink?: string;
  addLinkDisabled?: boolean;
  noAdd?: boolean;
  isBeforeFilters?: boolean;
  filters?: { [key: string]: any };
  filterName?: string;
  setFilter?: (filter: any, value: any) => void;
  FilterComponent?: any;
}

function Header({
  title,
  subTitle,
  btnTitle,
  btnSubtitle,
  Icon,
  addLink,
  addLinkDisabled = false,
  noAdd,
  isBeforeFilters = false,
  filters,
  filterName,
  setFilter,
  FilterComponent,
}: IHeader) {
  const roundedStyle = isBeforeFilters ? "rounded-t-lg" : "rounded-lg";

  const handleChangeFilter = useCallback(
    (name: any, value: any) => {
      // TODO ca va faire deux fetchData, mais super compliqué a optimiser,
      // il faut un debounce mais aussi modifier le fetchData pour que la fonction ne change jamais
      // gotoPage(0);
      if (setFilter) {
        setFilter(name, value);
      }
    },
    [setFilter]
  );

  return (
    <StickyWrapper isHeader isBeforeFilters={isBeforeFilters}>
      {/** Header */}
      <div className="flex justify-center relative lg:min-h-[70px]">
        <div
          className={`flex flex-col lg:flex-row justify-between lg:items-center bg-white px-4 pt-4 ${
            filterName ? "pb-0" : "pb-0 lg:pb-4"
          } border border-slate-150 w-full ${roundedStyle}`}
        >
          <div>
            <h1 className="flex items-center text-2xl font-bold gap-2 text-primary">
              <Icon className="w-6 h-6" />
              {title}
            </h1>
            {subTitle && <div className="text-secondary">{subTitle}</div>}

            {filters && filterName && FilterComponent && (
              <div className="mt-2">
                <FilterComponent
                  value={filters[filterName]}
                  data-cy={filterName}
                  onChange={(value: any) => {
                    handleChangeFilter(filterName, value);
                  }}
                />
              </div>
            )}
          </div>

          {/* Add button */}
          {!noAdd && addLink && (
            <Link
              to={addLink}
              className={`${
                addLinkDisabled ? "pointer-events-none" : ""
              } flex justify-end self-end mt-3 md:mt-0 mb-4 lg:mb-0`}
            >
              <div className="flex text-white -bottom-7 cursor-pointer">
                <div
                  className={`flex flex-col justify-center text-left px-4 py-2 bg-emerald-600 rounded-l-md ${
                    addLinkDisabled && "opacity-40"
                  }`}
                >
                  {btnTitle && (
                    <span className="font-bold text-right">{btnTitle}</span>
                  )}
                  {btnSubtitle && (
                    <span className="text-xxs text-right">{btnSubtitle}</span>
                  )}
                </div>
                <div
                  className={`flex flex-col items-center justify-center text-left px-2 py-2 bg-emerald-700 rounded-r-md ${
                    addLinkDisabled && "opacity-40"
                  }`}
                >
                  <PlusCircleIcon className="w-7 h-7" />
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </StickyWrapper>
  );
}

export default Header;
