import { Constants } from "constants/Constants";
import { getStoredData } from "./fnAsyncStorage";

type Props = {
  url: string;
  method: string;
  payload?: object | undefined;
  signal?: AbortSignal | null | undefined;
};

export const fetchJSON = async ({ url, method, payload, signal }: Props) => {
  const apiUrl = Constants.API_URL;
  const token = await getStoredData("token");

  const headers = new Headers();
  const baseUrl = `${apiUrl}/${url}`;

  headers.append("Content-Type", "application/json");

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const res = await fetch(baseUrl, {
    method,
    headers,
    signal,
    body: JSON.stringify(payload),
  });

  if (!res.ok) {
    const message = await res.text();

    let error: any;

    try {
      const response = JSON.parse(message);
      error = { message: response.error.message };
      response.error?.details?.errors?.forEach((err: any) => {
        error[err.path.join(".")] = err.message;
      });
    } catch (e) {
      error = { message };
    }

    throw error;
  }

  return res.json();
};

export default fetchJSON;
