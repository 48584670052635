/**
 * @param {File} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */

export type GetCroppedImgProps = {
  image: HTMLImageElement;
  imageUrl: string;
  fileType: string;
  crop: any;
  fileName: string;
  maxWidth: number;
  maxHeight: number;
};

export const getCroppedImg = async ({
  image,
  fileType,
  crop,
  fileName,
  maxWidth,
  maxHeight,
}: GetCroppedImgProps) => {
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  let dstWidth = crop.width * scaleX;

  let dstHeight = crop.height * scaleY;

  if (dstWidth > maxWidth) {
    const ratio = maxWidth / dstWidth;

    dstWidth = maxWidth;
    dstHeight *= ratio;
  }

  if (dstHeight > maxHeight) {
    const ratio = maxHeight / dstHeight;

    dstHeight = maxHeight;
    dstWidth *= ratio;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = dstWidth;
  canvas.height = dstHeight;

  ctx?.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    dstWidth,
    dstHeight
  );

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(
        (blob) => resolve(new File([blob as Blob], fileName)),
        fileType,
        0.8
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      reject();
    }
  });
};

export default getCroppedImg;
