import Button from "common/components/Button/Button";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import Status from "common/components/Status/Status";
import InfoGroup, { Info } from "common/components/InfoGroup/InfoGroup";
import { CourseHistory } from "types/CourseHistory";

function CourseHistoryItem({ item, onDelete }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const {
    courseName,
    course,
    userUId,
    timeToFinish,
    totalSkippedSteps,
    totalScore,
    userTypeLabel,
    userType,
    hasQuiz,
  } = item as CourseHistory;

  const canDeleteItem =
    !course ||
    currentUser?.isSuperadmin ||
    currentUser?.isAdmin ||
    (currentUser?.isPro && course.author?.id === currentUser?.id);

  const info: Info[] = [
    {
      name: timeToFinish ? `${timeToFinish} min` : "",
      infoKey: "forms.timeToFinish",
      icon: "ClockIcon",
    },
    {
      name: totalSkippedSteps
        ? `${totalSkippedSteps} ${
            totalSkippedSteps > 1
              ? t("forms.totalSkippedStepsMetrics")
              : t("forms.totalSkippedStepsMetric")
          }`
        : t("common.totalSkippedStepsNone"),
      infoKey: "forms.totalSkippedSteps",
      icon: "BsCardChecklist",
    },
    {
      nameKey: hasQuiz
        ? totalScore
          ? `${totalScore}pts`
          : ""
        : "common.noQuiz",
      infoKey: "forms.quizPoints",
      icon: "CiTrophy",
    },
  ];

  const authorInfo: Info[] = [
    {
      name: userUId,
      infoKey: "forms.userUId",
      icon: "UserIcon",
    },
    {
      name: userTypeLabel ?? userType?.name ?? "",
      infoKey: "forms.userType",
      icon: "UserGroupIcon",
    },
  ];

  return (
    <div className="flex px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <div className="flex flex-1">
        <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4">
          <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
            <div className="rounded-lg overflow-hidden w-full">
              <ImageComponent
                image={
                  course?.image
                    ? ImageFromStrapiMedia(course?.image)?.uri
                    : undefined
                }
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-center overflow-hidden">
            {/* head */}
            <div className="flex flex-col xl:flex-row w-full gap-4">
              <div className="flex flex-col flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {courseName}
                </h4>
              </div>
              <div className="flex justify-end items-start mb-2 lg:mb-0">
                <InfoGroup infoGroups={authorInfo} />
              </div>
            </div>
            {/* foot */}
            <div className="flex flex-col gap-2 pt-1 mt-2 border-t border-slate-200">
              <div className="responsive-flex-lg mt-1">
                <InfoGroup infoGroups={info} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l lg:pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        {item.status ? <Status status={item.status} /> : <div />}
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canDeleteItem}
                confirmMessage={t("actions.confirmDelete")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseHistoryItem;
