import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  previousPage: () => void;
  goToPage: (i: number) => void;
  nextPage: () => void;
  // currentPage: number;
  totalPage: number;
  disabled: boolean;
  pageIndex: number;
  itemsPerPage: number;
  itemsCount: number;
};

const prevNextButttonStyle =
  "relative inline-flex items-center px-2 py-2 border border-slate-200 bg-white text-sm font-medium";

function Pagination({
  previousPage,
  goToPage,
  nextPage,
  // currentPage,
  totalPage,
  disabled,
  pageIndex,
  itemsPerPage,
  itemsCount,
}: Props) {
  const { t } = useTranslation();
  let buttons = [];

  const currentPage = pageIndex;

  if (totalPage <= 7) {
    buttons = Array.from({ length: totalPage }).map((l, index) => index);
  } else if (currentPage < 4) {
    buttons = [
      ...Array.from({ length: 5 }).map((l, index) => index),
      -1,
      totalPage - 1,
    ];
  } else if (currentPage > totalPage - 5) {
    buttons = [
      0,
      -1,
      ...Array.from({ length: 5 }).map((l, index) => index + totalPage - 5),
    ];
  } else {
    buttons = [
      0,
      -1,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      -1,
      totalPage - 1,
    ];
  }

  const prevDisabled = currentPage === 0 || disabled;
  const nextDisabled = currentPage === totalPage - 1 || disabled;

  const getPrevNextStyle = (isDisabled: boolean) =>
    isDisabled
      ? "text-slate-300"
      : "text-slate-500 hover:bg-primary hover:text-white hover:border-primary cursor-pointer";

  return (
    <div className="py-3 mt-4 flex items-center justify-between w-full bg-slate-100 ">
      <div>
        <p className="text-sm text-slate-700">
          <span className="font-medium ml-1">
            {1 + pageIndex * itemsPerPage}
          </span>
          /
          <span className="font-medium">
            {itemsCount < pageIndex * itemsPerPage + itemsPerPage
              ? itemsCount
              : pageIndex * itemsPerPage + itemsPerPage}
          </span>{" "}
          {t("common.of")} <span className="font-medium">{itemsCount}</span>{" "}
          {t("common.results")}
        </p>
      </div>
      <div>
        <nav
          className="relative z-0 inline-flex rounded-md -space-x-px"
          aria-label="Pagination"
        >
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={prevDisabled}
            className={classNames(
              prevNextButttonStyle,
              "rounded-l-md",
              getPrevNextStyle(prevDisabled)
            )}
          >
            <span className="sr-only">{t("common.previous")}</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          {buttons.map((i) => {
            const className =
              currentPage === i
                ? "z-10 bg-secondary border-secondary text-white"
                : "bg-white border-slate-200 text-slate-500 hover:bg-primary hover:text-white hover:border-primary";

            if (i === -1) {
              return (
                <span
                  key={i}
                  className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-slate-200 text-slate-700"
                >
                  ...
                </span>
              );
            }

            return (
              <button
                key={i}
                type="button"
                disabled={currentPage !== i && disabled}
                onClick={() => goToPage(i)}
                aria-current="page"
                className={classNames(
                  className,
                  "relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                )}
              >
                {i + 1}
              </button>
            );
          })}
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={nextDisabled}
            className={classNames(
              prevNextButttonStyle,
              "rounded-r-md",
              getPrevNextStyle(nextDisabled)
            )}
          >
            <span className="sr-only">{t("common.next")}</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
}

export default Pagination;
